import React from "react";
import { withStyles } from "@mui/styles";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
// import { userCreateAction, userList } from "../actions/userActions";
import { CreateValidation } from "./FormValidation";
import {
  fabricList,
  fabricCreateAction,
} from "../../../redux/actions/fabricAction";
import API_URL from "../../../utils/api";

// import { userRoleList } from "../../../redux/actions/userRoleActions";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>shopping_bag</Icon>
      <Typography
        variant="h5"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "500",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, fabrics, measuringUnits, setNotify } = props;
  const [uom_qty, setUomqty] = React.useState("Roll");
  const [uom_tracking, setUomtracking] = React.useState("Roll");
  const [status, setStatus] = React.useState("Y");
  const [getCategory, setCategory] = React.useState({
    _id: "",
    category: "",
    error: false,
  });

  console.log("getCategory", getCategory);

  const [u_id, setU_id] = React.useState(1);

  const dispatch = useDispatch();
  const fabricCreate = useSelector((state) => state.fabricCreate);
  const { fabricCreates } = fabricCreate;
  console.log("fabricData", fabricCreates);

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const [job_date, set_job_date] = React.useState(materialDateInput);
  const [shipment_date, set_shipment_date] = React.useState("");

  React.useEffect(() => {
    axios
      .get(`${API_URL}/api/tracking_jobs/max-job-id`)
      .then((res) => {
        console.log("response ----:", res);
        setU_id(res.data);
      })
      .catch((err) => {
        console.log("Error ---:", err);
      });
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("buyer_name" in fieldValues)
      temp.buyer_name = fieldValues.buyer_name ? "" : "This field is required.";

    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "This field is required.";

    if ("color" in fieldValues)
      temp.color = fieldValues.color ? "" : "This field is required.";

    if ("width" in fieldValues)
      temp.width = fieldValues.width ? "" : "This field is required.";

    if ("style" in fieldValues)
      temp.style = fieldValues.style ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (getCategory.category === "") {
      setCategory({
        _id: "",
        category: "",
        error: true,
      });
    }

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  // const u_id = "F#" + uuidv4().substring(0, 15);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate, getCategory);
  const job_id = u_id;
  const order_item_id = values.order_item_id;
  const tracking_qty = values.lot_qty_roll;
  const buyer_name = values.buyer_name;
  const style = values.style;
  const po = values.po;
  const pi_no = values.pi_no;
  const type = values.type;
  const category = getCategory.category;
  const composition = values.composition;
  const construction = values.construction;
  const width = values.width;
  const color = values.color;
  const gsm = values.gsm;
  // const uom_tracking = values.uom_tracking;
  const order_qty = values.order_qty_roll;
  // const uom_qty =values.uom_qty;
  const item_type = "Fabrics";
  const conversion_rate = values.order_qty_yds / values.order_qty_roll;
  const lot_conversion_rate = values.lot_qty_yds / values.lot_qty_roll;
  const lot_no = values.lot_no;
  const others = values.others;
  const remarks = values.remarks;
  const shrinkage_length = values.shrinkage_length;
  const shrinkage_width = values.shrinkage_width;
  const pattern = values.pattern;
  const shade = values.shade;

  console.log(
    "post dataFabric",
    order_item_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    pi_no,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    job_id,
    shipment_date,
    lot_conversion_rate,
    shrinkage_length,
    shrinkage_width,
    pattern,
    shade
  );

  const submitHandler = (e) => {
    console.log("fabrics validate Data", validate());
    e.preventDefault();
    if (validate() && getCategory.category !== "") {
      dispatch(
        fabricCreateAction(
          order_item_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          pi_no,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks,
          job_id,
          shipment_date,
          lot_no,
          lot_conversion_rate,
          shrinkage_length,
          shrinkage_width,
          pattern,
          shade
        )
      );

      // if (getCategory.category) {
      //   setCategory({
      //     _id: "",
      //     category: "",
      //     error: true,
      //   });
      // }

      setOpenPopup(false);
      setTimeout(() => {
        dispatch(fabricList());
        set_shipment_date("");
        setCategory({
          _id: "",
          category: "",
        });
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  console.log("updateDataFabrics", {
    order_item_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    job_id,
  });

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
    set_shipment_date("");
    setCategory({
      _id: "",
      category: "",
    });
  };

  // React.useEffect(() => {
  //   if (values.category !== "") {
  //     setCategory({
  //       _id: u_id,
  //       category: values.category,
  //       error: false,
  //     });
  //   }
  // }, [values]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          // setCurrency={setCurrency}
          setUomqty={setUomqty}
          setUomtracking={setUomtracking}
          fabrics={fabrics}
          measuringUnits={measuringUnits}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          u_id={u_id}
          uom_qty={uom_qty}
          uom_tracking={uom_tracking}
          set_job_date={set_job_date}
          job_date={job_date}
          status={status}
          setStatus={setStatus}
          getCategory={getCategory}
          setCategory={setCategory}
          CreateValidation={CreateValidation}
          validate={validate}
          values={values}
          setValues={setValues}
          shipment_date={shipment_date}
          set_shipment_date={set_shipment_date}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

import axios from "axios";
import {
  REQUISITION_REQUEST,
  REQUISITION_SUCCESS,
  REQUISITION_FAIL,
  REQUISITION_CREATE_REQUEST,
  REQUISITION_CREATE_SUCCESS,
  REQUISITION_CREATE_FAIL,
  REQUISITION_CREATE_RESET,
  REQUISITION_UPDATE_REQUEST,
  REQUISITION_UPDATE_SUCCESS,
  REQUISITION_UPDATE_FAIL,
  REQUISITION_UPDATE_RESET,
  REQUISITION_DELETE_REQUEST,
  REQUISITION_DELETE_SUCCESS,
  REQUISITION_DELETE_FAIL,
} from "../constants/requisitionConstant";

import API_URL from "../../utils/api";

export const requisitionList = () => async (dispatch) => {
  try {
    dispatch({
      type: REQUISITION_REQUEST,
    });

    const { data } = await axios.get(`${API_URL}/api/requisition`);

    dispatch({
      type: REQUISITION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REQUISITION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const requisitionCreateAction =
  (
    req_roll_qty,
    req_yds_qty,
    job_id,
    pi_no,
    buyer_name,
    style,
    po,
    composition,
    construction,
    type,
    category,
    color,
    width,
    gsm,
    others,
    shrinkage_length,
    shrinkage_width,
    pattern,
    shade,
    shipment_date
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REQUISITION_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/requisition`,
        {
          req_roll_qty,
          req_yds_qty,
          job_id,
          pi_no,
          buyer_name,
          style,
          po,
          composition,
          construction,
          type,
          category,
          color,
          width,
          gsm,
          others,
          shrinkage_length,
          shrinkage_width,
          pattern,
          shade,
          shipment_date,
        },
        config
      );

      dispatch({
        type: REQUISITION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUISITION_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const requisitionUpdateAction =
  (
    id,
    order_item_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    pi_no,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    job_id,
    lot_no,
    lot_conversion_rate,
    shrinkage_length,
    shrinkage_width,
    pattern,
    shade
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: REQUISITION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/requisition/${id}`,
        {
          order_item_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          pi_no,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks,
          job_id,
          lot_no,
          lot_conversion_rate,
          shrinkage_length,
          shrinkage_width,
          pattern,
          shade,
        },
        config
      );

      dispatch({
        type: REQUISITION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: REQUISITION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const requisitionDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: REQUISITION_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios.delete(
      `${API_URL}/api/requisition/${id}`,
      config
    );

    dispatch({
      type: REQUISITION_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REQUISITION_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Slide,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";

import {
  trackingLocationDeleteAction,
  trackingLocationList,
} from "../../../redux/actions/trackingLocationAction";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeletePopup = (props) => {
  const { openDeletePopup, setOpenDeletePopup, id, item, notify, setNotify } =
    props;

  const handleClose = () => {
    setOpenDeletePopup(false);
  };

  const dispatch = useDispatch();

  // const roleFunctionDelete = useSelector((state) => state.roleFunctionDelete);

  // const { response } = roleFunctionDelete;

  // console.log("res", response);

  const deleteHandler = () => {
    dispatch(trackingLocationDeleteAction(id));
    setOpenDeletePopup(false);
    setTimeout(() => {
      dispatch(trackingLocationList());
    }, 2000);
  };

  return (
    <div>
      <Dialog
        open={openDeletePopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        fullWidth
      >
        <DialogTitle>
          {" "}
          <p style={{ fontSize: 14 }}>
            Are you sure you want to remove{" "}
            <strong>{`${item.location_name}`}</strong>?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={deleteHandler}
            color="secondary"
            variant="contained"
            size="small"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeletePopup;

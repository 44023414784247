import {
  TRACKING_LOCATION_REQUEST,
  TRACKING_LOCATION_SUCCESS,
  TRACKING_LOCATION_FAIL,
  TRACKING_LOCATION_CREATE_REQUEST,
  TRACKING_LOCATION_CREATE_SUCCESS,
  TRACKING_LOCATION_CREATE_FAIL,
  TRACKING_LOCATION_CREATE_RESET,
  TRACKING_LOCATION_UPDATE_REQUEST,
  TRACKING_LOCATION_UPDATE_SUCCESS,
  TRACKING_LOCATION_UPDATE_FAIL,
  TRACKING_LOCATION_UPDATE_RESET,
  TRACKING_LOCATION_DELETE_REQUEST,
  TRACKING_LOCATION_DELETE_SUCCESS,
  TRACKING_LOCATION_DELETE_FAIL,
} from "../constants/trackingLocationConstant";

export const trackingLocationReducer = (state = { locations: [] }, action) => {
  switch (action.type) {
    case TRACKING_LOCATION_REQUEST:
      return { loading: true, locations: [] };
    case TRACKING_LOCATION_SUCCESS:
      return { loading: false, locations: action.payload };
    case TRACKING_LOCATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingLocationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_LOCATION_CREATE_REQUEST:
      return { loading: true };
    case TRACKING_LOCATION_CREATE_SUCCESS:
      return { loading: false, locationCreates: action.payload };
    case TRACKING_LOCATION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TRACKING_LOCATION_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const trackingLocationUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_LOCATION_UPDATE_REQUEST:
      return { loading: true };
    case TRACKING_LOCATION_UPDATE_SUCCESS:
      return { loading: false, locationUpdates: action.payload };
    case TRACKING_LOCATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TRACKING_LOCATION_UPDATE_RESET:
      return { locationCreates: {} };
    default:
      return state;
  }
};

export const trackingLocationDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_LOCATION_DELETE_REQUEST:
      return { loading: true };
    case TRACKING_LOCATION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRACKING_LOCATION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

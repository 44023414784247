import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const itemTypes = [
  {
    id: 1,
    name: "Fabrics",
  },
  {
    id: 2,
    name: "Cartons",
  },
  {
    id: 3,
    name: "Accessories",
  },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setPhoto,
    item,
    value,
    handleInputChange,
    error,
    helperText,
    setItemType,
    item_type,
  } = props;

  console.log("edit data", item);

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    setPhoto(files[0]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <FormControl fullWidth sx={{ s: 1 }} size="small">
              <InputLabel id="demo-simple-select-label">Bin Type *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Bin Type *"
                onChange={(e) => setItemType(e.target.value)}
                className={classes.textField}
                MenuProps={MenuProps}
                defaultValue={item ? item.item_type : item_type}
              >
                {itemTypes
                  ? itemTypes.map((item) => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="location_name"
              required
              variant="outlined"
              fullWidth
              id="location_name"
              label="Bin Name"
              autoFocus
              size="small"
              className={classes.textField}
              error={error.location_name}
              defaultValue={item ? item.location_name : null}
              helperText={helperText.location_name}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="psl"
              required
              variant="outlined"
              fullWidth
              id="psl"
              label="Print Serial"
              autoFocus
              size="small"
              className={classes.textField}
              error={error.psl}
              defaultValue={item ? item.psl : null}
              helperText={helperText.psl}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              name="location_description"
              variant="outlined"
              fullWidth
              id="location_description"
              label="Bin Description"
              autoFocus
              size="small"
              multiline
              rows={3}
              className={classes.textField}
              defaultValue={item ? item.location_description : null}
              error={error.location_description}
              helperText={helperText.location_description}
              onChange={handleInputChange}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel style={{ marginBottom: 10 }}>Photo</FormLabel>
              <input
                accept="image/*"
                id="contained-button-file"
                multiple
                type="file"
                onChange={handlePhotoUpload}
              />
            </FormControl>
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

import React from "react";

//mui
import { Snackbar, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyle = makeStyles((theme) => ({
  root: {
    marginTop: 150,
    right: theme.spacing(2),
  },
}));

function Notification(props) {
  const classes = useStyle();
  const { notify, setNotify } = props;

  return (
    <Snackbar
      className={classes.root}
      open={notify.isOpen}
      elevation={6}
      variant="filled"
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={() => setNotify({ isOpen: false })}
    >
      <Alert severity={notify.type}>{notify.message}</Alert>
    </Snackbar>
  );
}
export default Notification;

import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  cartoonList,
  cartoonUpdateAction,
} from "../../../redux/actions/cartoonAction";
import { EditValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>assessment</Icon>
      <Typography
        variant="h6"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red", marginTop: "-10px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openEditPopup,
    setOpenEditPopup,
    item,
    notify,
    setNotify,
    measuringUnits,
    cartoons,
  } = props;

  const id = item.id;

  const [uom_qty, setUomqty] = React.useState("");
  const [uom_tracking, setUomtracking] = React.useState("");
  const [job_date, set_job_date] = React.useState("");

  useEffect(() => {
    setUomqty(item.uom_qty);
    setUomtracking(item.uom_tracking);
    set_job_date(item.job_date);
  }, [item]);

  const created_by = "Shahibuzzaman";
  const modified_by = "Shahibuzzaman2";

  const dispatch = useDispatch();
  const cartoonUpdate = useSelector((state) => state.cartoonUpdate);
  const { cartoonUpdates } = cartoonUpdate;
  console.log("cartonsDAta Update", cartoonUpdates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("buyer_name" in fieldValues)
      temp.buyer_name = fieldValues.buyer_name ? "" : "This field is required.";

    if ("po" in fieldValues)
      temp.po = fieldValues.po ? "" : "This field is required.";

    if ("style" in fieldValues)
      temp.style = fieldValues.style ? "" : "This field is required.";

    // if ("uom_tracking" in fieldValues)
    //   temp.uom_tracking = fieldValues.uom_tracking
    //     ? ""
    //     : "This field is required.";

    // if ("uom_qty" in fieldValues)
    //   temp.uom_qty = fieldValues.uom_qty ? "" : "This field is required.";
    
    if ("tracking_qty" in fieldValues)
      temp.tracking_qty = fieldValues.tracking_qty
        ? ""
        : "This field is required.";  

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  console.log("lll", values);

  const order_item_id = values.order_item_id;
  const job_id = values.job_id;
  const tracking_qty = values.tracking_qty;
  const buyer_name = values.buyer_name;
  const style = values.style;
  const po = values.po;
  const type = "Cartoon";
  const category = values.category;
  const composition = values.composition;
  const construction = values.construction;
  const width = values.width;
  const color = values.color;
  const gsm = values.gsm;
  // const uom_tracking = values.uom_tracking;
  const order_qty = values.order_qty;
  // const uom_qty = values.uom_qty;
  const item_type = values.item_type;
  const conversion_rate = values.conversion_rate;
  const others = values.others;
  const remarks = values.remarks;

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        cartoonUpdateAction(
          item.id,
          order_item_id,
          job_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(cartoonList());
      }, 1000);
      // setNotify({
      //   isOpen: true,
      //   message: "Submitted Successfully",
      //   type: "success",
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      onClose={handleClose}
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Modify Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          item={item}
          handleInputChange={handleInputChange}
          error={errors}
          value={values}
          helperText={errors}
          setUomqty={setUomqty}
          setUomtracking={setUomtracking}
          cartoons={cartoons}
          measuringUnits={measuringUnits}
          set_job_date={set_job_date}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

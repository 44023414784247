import React, { useEffect, useState } from "react";
//mui
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { trackingJobsListFix } from "../../../redux/actions/trackingJobsAction";
import { permissionAction } from "../../../redux/actions/permissionActions";
import { trackingCodesListFix } from "../../../redux/actions/trackingCodesAction";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState(32);

  const trackingJob = useSelector((state) => state.trackingJob);

  const { trackingJobs } = trackingJob;

  const trackingCode = useSelector((state) => state.trackingCode);

  const { trackingCodes } = trackingCode;

  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;

  console.log("permission - xyz", permissions);
  console.log(userData);

  const pathName = window.location.pathname;
  useEffect(() => {
    // dispatch(trackingCodesList());
    if (pathName === "/app/fabrics_qr_code") {
      dispatch(trackingJobsListFix({ item_type: "Fabric" }));
      // dispatch(trackingCodesListFix({'item_type':"Fabric"}));
    }
    if (pathName === "/app/cartons_qr_code") {
      dispatch(trackingJobsListFix({ item_type: "Carton" }));
      // dispatch(trackingCodesListFix({'item_type':"Carton"}));
    }
    if (pathName === "/app/fabrics_qr_code") {
      dispatch(trackingCodesListFix({ item_type: "Fabric" }));
    }
    if (pathName === "/app/cartons_qr_code") {
      dispatch(trackingCodesListFix({ item_type: "Carton" }));
    }
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData, pathName]);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Table
          trackingJobs={trackingJobs}
          permissions={permissions}
          trackingCodes={trackingCodes}
          trackingJobs={trackingJobs}
        />
      </div>
    </div>
  );
};

export default Body;

import axios from "axios";
import {
  TRACKING_PURPOSES_REQUEST,
  TRACKING_PURPOSES_SUCCESS,
  TRACKING_PURPOSES_FAIL,
  TRACKING_PURPOSES_CREATE_REQUEST,
  TRACKING_PURPOSES_CREATE_SUCCESS,
  TRACKING_PURPOSES_CREATE_FAIL,
  TRACKING_PURPOSES_UPDATE_REQUEST,
  TRACKING_PURPOSES_UPDATE_SUCCESS,
  TRACKING_PURPOSES_UPDATE_FAIL,
  TRACKING_PURPOSES_DELETE_REQUEST,
  TRACKING_PURPOSES_DELETE_SUCCESS,
  TRACKING_PURPOSES_DELETE_FAIL,
} from "../constants/trackingPurposesConstants";
import API_URL from "../../utils/api";

export const trackingPurposesList = () => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_PURPOSES_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(
      `${API_URL}/api/tracking-purposes`,
      config
    );

    dispatch({
      type: TRACKING_PURPOSES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_PURPOSES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const trackingPurposesCreateAction =
  (purpose, description, item_type) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_PURPOSES_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/tracking-purposes`,
        {
          purpose,
          description,
          item_type,
        },
        config
      );

      console.log("data4", purpose, description);

      dispatch({
        type: TRACKING_PURPOSES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_PURPOSES_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingPurposesUpdateAction =
  (id, purpose, description, item_type, created_by, modified_by) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_PURPOSES_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/tracking-purposes/${id}`,
        { purpose, description, item_type, created_by, modified_by },
        config
      );

      console.log("update data", purpose, description, created_by, modified_by);

      dispatch({
        type: TRACKING_PURPOSES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_PURPOSES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingPurposesDeleteAction =
  (id, setNotify) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_PURPOSES_DELETE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      console.log("id", id);

      const { data } = await axios
        .delete(`${API_URL}/api/tracking-purposes/${id}`, config)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log("abcd", error.response);
        });

      console.log("ooo", data);

      dispatch({
        type: TRACKING_PURPOSES_DELETE_SUCCESS,
      });
    } catch (response) {
      dispatch({
        type: TRACKING_PURPOSES_DELETE_FAIL,
        payload: response ? response.data : response,
      });
    }
  };

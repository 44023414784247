import React, { useEffect, useState } from "react";

//mui
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { fabricList } from "../../../redux/actions/fabricAction";
import { measuringUnitList } from "../../../redux/actions/measuringUnitAction";
import { permissionAction } from "../../../redux/actions/permissionActions";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const dispatch = useDispatch();
  const { userData } = props;
  const [app_menu_id, setAppMenuId] = useState("Fabrics");

  const fabric = useSelector((state) => state.fabric);
  const { fabrics } = fabric;

  const measuringUnit = useSelector((state) => state.measuringUnit);
  const { measuringUnits } = measuringUnit;

  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;

  console.log("permission", permissions);

  console.log("appUser", userData);

  useEffect(() => {
    dispatch(fabricList());
    dispatch(measuringUnitList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  const modifiedData = fabrics
    ? fabrics
        .filter((item) => {
          return item.item_type === "Fabrics";
        })
        .map((item) => item)
    : null;

  console.log("log", modifiedData);

  return (
    <div style={{ width: "100%", minHeight: "65vh" }}>
      <div style={{ marginTop: 20 }}>
        {permissions.length ? (
          <Table
            fabrics={modifiedData}
            measuringUnits={measuringUnits}
            permissions={permissions}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "65vh",
            }}
          >
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedCard from "../ReportCard";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import { Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fabricList } from "../../../../redux/actions/fabricAction";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import API_URL from "../../../../utils/api";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Index = () => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = React.useState("");
  const [isLoading, setLoading] = React.useState(true);
  const [filerParameter, setFilterParameter] = React.useState([]);
  const [data, setData] = React.useState("");
  const [job_id, setJobId] = React.useState([]);
  const [style, setStyle] = React.useState([]);
  const [buyer_name, setBuyerName] = React.useState([]);
  const [construction, setConstruction] = React.useState([]);
  const [composition, setComposition] = React.useState([]);
  const [type, setType] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [color, setColor] = React.useState([]);
  const [width, setWidth] = React.useState([]);
  const [gsm, setGsm] = React.useState([]);
  const [filerAction, setFilterAction] = React.useState({
    item_type: "Fabrics",
  });

  let history = useHistory();

  React.useEffect(() => {
    fetch(`${API_URL}/api/tracking-jobs/filter-app-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ item_type: "Fabrics" }),
    }).then((response) => {
      response.json().then((result) => {
        console.log("Checking user Data", result);
        setFilterParameter([result]);
      });
    });
  }, [dispatch]);

  console.log("jobData", job_id);
  console.log("style", style);
  console.log("filerAction", JSON.stringify(filerAction));

  React.useEffect(() => {
    var finalArray = style.map(function (obj) {
      return obj.style;
    });
    setFilterAction({
      ...filerAction,
      style: finalArray,
    });
  }, [style]);

  React.useEffect(() => {
    var finalArray = job_id.map(function (obj) {
      return obj.job_id;
    });
    setFilterAction({
      ...filerAction,
      job_id: finalArray,
    });
  }, [job_id]);

  React.useEffect(() => {
    var finalArray = buyer_name.map(function (obj) {
      return obj.buyer_name;
    });
    setFilterAction({
      ...filerAction,
      buyer_name: finalArray,
    });
  }, [buyer_name]);

  React.useEffect(() => {
    var finalArray = construction.map(function (obj) {
      return obj.construction;
    });
    setFilterAction({
      ...filerAction,
      construction: finalArray,
    });
  }, [construction]);

  React.useEffect(() => {
    var finalArray = composition.map(function (obj) {
      return obj.composition;
    });
    setFilterAction({
      ...filerAction,
      composition: finalArray,
    });
  }, [composition]);

  React.useEffect(() => {
    var finalArray = type.map(function (obj) {
      return obj.type;
    });
    setFilterAction({
      ...filerAction,
      type: finalArray,
    });
  }, [type]);

  React.useEffect(() => {
    var finalArray = category.map(function (obj) {
      return obj.category;
    });
    setFilterAction({
      ...filerAction,
      category: finalArray,
    });
  }, [category]);

  React.useEffect(() => {
    var finalArray = color.map(function (obj) {
      return obj.color;
    });
    setFilterAction({
      ...filerAction,
      color: finalArray,
    });
  }, [color]);

  React.useEffect(() => {
    var finalArray = width.map(function (obj) {
      return obj.width;
    });
    setFilterAction({
      ...filerAction,
      width: finalArray,
    });
  }, [width]);

  React.useEffect(() => {
    var finalArray = gsm.map(function (obj) {
      return obj.gsm;
    });
    setFilterAction({
      ...filerAction,
      gsm: finalArray,
    });
  }, [gsm]);

  React.useEffect(() => {
    if (filerAction !== "") {
      fetch(`${API_URL}/api/tracking-locations/report-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(filerAction),
      }).then((response) => {
        response.json().then((result) => {
          console.log("report Data", result);

          const groupBy = (data, groupingProp) => {
            const map = new Map();
            data.forEach((entry) => {
              const groupKey = entry[groupingProp];
              const list = map.get(groupKey);
              if (list == null) {
                map.set(groupKey, [entry]);
              } else {
                list.push(entry);
              }
            });
            return [...map.values()];
          };

          console.log("abcd", groupBy(result.response, "job_id"));

          setReportData(result.response);
          setData(groupBy(result.response, "job_id"));
          setLoading(false);
        });
      });
    }
  }, [filerAction]);

  console.log("reportData", reportData);

  //display-data-filter

  const filterA = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("A");
        })
        .map((item) => item)
    : [];

  const filterB = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("B");
        })
        .map((item) => item)
    : [];

  const filterC = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("C");
        })
        .map((item) => item)
    : [];

  const filterD = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("D");
        })
        .map((item) => item)
    : [];

  const filterE = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("E");
        })
        .map((item) => item)
    : [];

  const filterF = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("F");
        })
        .map((item) => item)
    : [];

  const filterG = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("G");
        })
        .map((item) => item)
    : [];

  const filterH = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("H");
        })
        .map((item) => item)
    : [];

  const filterI = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("I");
        })
        .map((item) => item)
    : [];

  const filterJ = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("J");
        })
        .map((item) => item)
    : [];

  const filterK = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("K");
        })
        .map((item) => item)
    : [];

  const filterL = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("L");
        })
        .map((item) => item)
    : [];

  const filterM = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("M");
        })
        .map((item) => item)
    : [];

  const filterN = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("N");
        })
        .map((item) => item)
    : [];

  const filterO = reportData.length
    ? reportData
        .filter((item) => {
          return item.location_name.includes("O");
        })
        .map((item) => item)
    : [];

  console.log("A", filterA);

  return (
    <Box sx={{ flexGrow: 1 }} style={{ width: "100%", height: "100%" }}>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={9} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Icon style={{ fontSize: 22, marginTop: 20, color: "#13292A" }}>
                assessment
              </Icon>
              <h3 style={{ marginLeft: 5, color: "#13292A" }}>
                Fabric Reports
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -30,
                fontSize: 11,
                marginLeft: 25,
                color: "#13292A",
              }}
            >
              <p>Reports</p>
              <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
              <p> Fabric Reports</p>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={4} align="right">
            <div style={{ marginTop: 25 }}>
              <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                style={{ fontSize: 12, color: "#13292A" }}
                style={{ minWidth: 150, minHeight: 40, marginBottom: 10 }}
                onClick={() => {
                  history.push({
                    pathname: `/app/doc/fabric_stock_reports`,
                    state: data,
                  });
                }}
                // disabled={!insertPermission}
              >
                Print
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid
          container
          xs={12}
          style={{ backgroundColor: "", minHeight: "10vh" }}
        >
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].job_id : []}
              disableCloseOnSelect
              onChange={(e, v) => setJobId(v)}
              getOptionLabel={(option) => option.job_id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.job_id}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Job Id" placeholder="Job Id" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].buyer_name : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.buyer_name}
              onChange={(e, v) => setBuyerName(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.buyer_name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyer Name"
                  placeholder="Select Buyer"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].construction : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.construction}
              onChange={(e, v) => setConstruction(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.construction}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Construction"
                  placeholder="Construction"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].composition : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.composition}
              onChange={(e, v) => setComposition(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.composition}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Composition"
                  placeholder="Composition"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].type : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.type}
              onChange={(e, v) => setType(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.type}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Type" placeholder="Type" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].category : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.category}
              onChange={(e, v) => setCategory(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.category}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  placeholder="Category"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].style : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.style}
              onChange={(e, v) => setStyle(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.style}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Style" placeholder="Style" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].color : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.color}
              onChange={(e, v) => setColor(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.color}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Color" placeholder="Color" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].width : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.width}
              onChange={(e, v) => setWidth(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.width}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Width" placeholder="Width" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].gsm : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.gsm}
              onChange={(e, v) => setGsm(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.gsm}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="GSM" placeholder="GSM" />
              )}
            />
          </Grid>
        </Grid>
        <>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                minWidth: "100vw",
                marginTop: 50,
                minHeight: "60vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ minHeight: "60vh", minWidth: "100%" }}>
              {filterA.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      A
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterA} />
                  </Grid>
                </Grid>
              ) : null}
              {filterB.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      B
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterB} />
                  </Grid>
                </Grid>
              ) : null}
              {filterC.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      C
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterC} />
                  </Grid>
                </Grid>
              ) : null}
              {filterD.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      D
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterD} />
                  </Grid>
                </Grid>
              ) : null}
              {filterE.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      E
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterE} />
                  </Grid>
                </Grid>
              ) : null}
              {filterF.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      F
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterF} />
                  </Grid>
                </Grid>
              ) : null}
              {filterG.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      G
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterG} />
                  </Grid>
                </Grid>
              ) : null}
              {filterH.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      H
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterH} />
                  </Grid>
                </Grid>
              ) : null}
              {filterI.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      I
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterI} />
                  </Grid>
                </Grid>
              ) : null}
              {filterJ.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      J
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterJ} />
                  </Grid>
                </Grid>
              ) : null}
              {filterK.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      K
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterK} />
                  </Grid>
                </Grid>
              ) : null}
              {filterL.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      L
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterL} />
                  </Grid>
                </Grid>
              ) : null}
              {filterM.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      M
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterM} />
                  </Grid>
                </Grid>
              ) : null}
              {filterN.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      N
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterN} />
                  </Grid>
                </Grid>
              ) : null}
              {filterO.length ? (
                <Grid
                  container
                  xs={12}
                  style={{
                    marginTop: 30,
                    border: "1px solid grey",
                  }}
                  justify="center"
                >
                  <Grid
                    item
                    xs={1}
                    align="center"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "green",
                    }}
                  >
                    <text
                      style={{
                        fontSize: 36,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      O
                    </text>
                  </Grid>
                  <Grid item xs={11}>
                    <OutlinedCard reportData={filterO} />
                  </Grid>
                </Grid>
              ) : null}
            </div>
          )}
        </>
      </Grid>
    </Box>
  );
};

export default Index;

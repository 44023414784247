import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

//mui
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

//redux
import { Provider } from "react-redux";

import { BrowserRouter as Router } from "react-router-dom";

//components
import Themes from "./components/utils/themes";
import store from "./components/redux/store";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={Themes.default}>
      <Router>
        <CssBaseline />
        <App />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const { item, set_printable, printable } = props;

  const handleChange = (event) => {
    set_printable(event.target.value);
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Printable Status</FormLabel>
              <RadioGroup
                aria-label="status"
                name="status1"
                defaultValue={item ? item.printable : printable}
                onChange={handleChange}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <FormControlLabel
                  value="Y"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>
                      Non Printable
                    </Typography>
                  }
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label={
                    <Typography style={{ fontSize: 14 }}>Printable</Typography>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

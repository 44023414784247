import React, { useState, useEffect, useRef } from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { makeStyles, withStyles } from "@mui/styles";

const SearchBox = styled(TextField)(() => ({
  "& fieldset": {},
}));

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftColumn: {
    borderBottom: "1px solid grey",
    borderLeft: "1px solid grey",
    padding: 7,
    backgroundColor: "#EAEAEA",
  },
  leftColumnRemarks: {
    borderBottom: "1px solid grey",
    borderLeft: "1px solid grey",
    paddingLeft: 7,
    paddingTop: 18,
    paddingBottom: 18,
    paddingRight: 7,
    backgroundColor: "#EAEAEA",
  },
  rightColumn: {},

  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .Mui-error": {
      color: "#FF1010",
      borderWidth: 2,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
        borderRadius: `0px 0 0 0px`,
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

const InputForm = (props) => {
  const classes = useStyles();
  const scrollRef = useRef(null);

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setUomqty,
    setUomtracking,
    setCurrency,
    measuringUnits,
    item,
    u_id,
    uom_qty,
    uom_tracking,
    set_job_date,
    shipment_date,
    set_shipment_date,
    job_date,
    fabrics,
    setStatus,
    status,
    getCategory,
    setCategory,
    CreateValidation,
    validate,
    req_yds_qty,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  console.log("edit data", item);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  const categoryList = removeDuplicates(fabrics, "category");

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [scrollRef.current]);

  useEffect(() => {
    if (error.req_roll_qty) {
      alert(`${error.req_roll_qty}`);
    }
  }, [error]);

  return (
    <div className={classes.root} ref={scrollRef}>
      <form className={classes.form} noValidate>
        <Grid container>
          <Grid
            container
            xs={6}
            sm={6}
            style={{ width: "100%", borderTop: "1px solid grey" }}
          >
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Roll Qty</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Yds Qty</Typography>
            </Grid>
          </Grid>
          <Grid container xs={6} sm={6} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="req_roll_qty"
                id="req_roll_qty"
                autoFocus
                fullWidth
                size="small"
                className={classes.textField}
                defaultValue={item ? item.req_roll_qty : null}
                error={error.req_roll_qty}
                onChange={handleInputChange}
                placeholder="Enter Roll Qty*"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="req_yds_qty"
                required
                fullWidth
                id="req_yds_qty"
                autoFocus
                size="small"
                className={classes.textField}
                value={req_yds_qty}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

import React from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
// import { userCreateAction, userList } from "../actions/userActions";
import { CreateValidation } from "./FormValidation";
import {
  measuringUnitList,
  measuringUnitCreateAction,
} from "../../../redux/actions/measuringUnitAction";
// import { userRoleList } from "../../../redux/actions/userRoleActions";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>assessment</Icon>
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const { openPopup, setOpenPopup, notify, setNotify } = props;

  const created_by = "Shahibuzzaman";
  const modified_by = "Shahibuzzaman2";

  const dispatch = useDispatch();
  const measuringUnitCreate = useSelector((state) => state.measuringUnitCreate);
  const { measuringUnitCreates } = measuringUnitCreate;
  console.log("addda", measuringUnitCreates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("uom" in fieldValues)
      temp.uom = fieldValues.uom ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const uom = values.uom;
  const description = values.description;

  console.log("dimention data", uom, description, created_by, modified_by);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(measuringUnitCreateAction(uom, description));
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(measuringUnitList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: "Submitted Successfully",
      //   type: "success",
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedCard from "./RequisitionCard";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import PrintIcon from "@mui/icons-material/Print";
import { Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fabricList } from "../../../../redux/actions/fabricAction";
import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import API_URL from "../../../../utils/api";
import Table from "./Table";
import { requisitionList } from "../../../../redux/actions/requisitionAction";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Index = () => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = React.useState("");
  const [isLoading, setLoading] = React.useState(true);
  const [filerParameter, setFilterParameter] = React.useState([]);
  const [data, setData] = React.useState("");
  const [qrList, setQrList] = React.useState("");
  const [job_id, setJobId] = React.useState("");
  const [style, setStyle] = React.useState("");
  const [buyer_name, setBuyerName] = React.useState("");
  const [construction, setConstruction] = React.useState("");
  const [composition, setComposition] = React.useState("");
  const [type, setType] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [color, setColor] = React.useState("");
  const [width, setWidth] = React.useState("");
  const [gsm, setGsm] = React.useState("");
  const [pi_no, setPiNo] = React.useState("");
  const [po, setPo] = React.useState("");
  const [shrinkage_length, setShrinkageLength] = React.useState("");
  const [shrinkage_width, setShrinkageWidth] = React.useState("");
  const [pattern, setPattern] = React.useState("");
  const [shade, setShade] = React.useState("");
  const [others, setOthers] = React.useState("");
  const [shipment_date, set_shipment_date] = React.useState("");
  const [stockCount, setStockCount] = React.useState([]);
  const [reqData, setReqData] = React.useState([]);
  const [filerAction, setFilterAction] = React.useState({
    item_type: "Fabrics",
  });

  let history = useHistory();

  React.useEffect(() => {
    fetch(`${API_URL}/api/requisition/filter-app-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ item_type: "Fabrics" }),
    }).then((response) => {
      response.json().then((result) => {
        console.log("Checking user Data", result);
        setFilterParameter([result]);
      });
    });
  }, [dispatch]);

  console.log("filerAction", JSON.stringify(filerAction));

  React.useEffect(() => {
    if (style !== "") {
      setFilterAction({
        ...filerAction,
        style: style ? [style.style] : [],
      });
    }
  }, [style]);

  React.useEffect(() => {
    if (shipment_date !== "") {
      setFilterAction({
        ...filerAction,
        shipment_date: [shipment_date],
      });
    }
  }, [shipment_date]);

  React.useEffect(() => {
    if (shrinkage_length !== "") {
      setFilterAction({
        ...filerAction,
        shrinkage_length: shrinkage_length
          ? [shrinkage_length.shrinkage_length]
          : [],
      });
    }
  }, [shrinkage_length]);

  React.useEffect(() => {
    if (shrinkage_width !== "") {
      setFilterAction({
        ...filerAction,
        shrinkage_width: shrinkage_width
          ? [shrinkage_width.shrinkage_width]
          : [],
      });
    }
  }, [shrinkage_width]);

  React.useEffect(() => {
    if (pattern !== "") {
      setFilterAction({
        ...filerAction,
        pattern: pattern ? [pattern.pattern] : [],
      });
    }
  }, [pattern]);

  React.useEffect(() => {
    if (shade !== "") {
      setFilterAction({
        ...filerAction,
        shade: shade ? [shade.shade] : [],
      });
    }
  }, [shade]);

  React.useEffect(() => {
    if (others !== "") {
      setFilterAction({
        ...filerAction,
        others: others ? [others.others] : [],
      });
    }
  }, [others]);

  React.useEffect(() => {
    if (job_id !== "") {
      setFilterAction({
        ...filerAction,
        job_id: job_id ? [job_id.job_id] : [],
      });
    }
  }, [job_id]);

  React.useEffect(() => {
    if (pi_no !== "") {
      setFilterAction({
        ...filerAction,
        pi_no: pi_no ? [pi_no.pi_no] : [],
      });
    }
  }, [pi_no]);

  React.useEffect(() => {
    if (buyer_name !== "") {
      setFilterAction({
        ...filerAction,
        buyer_name: buyer_name ? [buyer_name.buyer_name] : [],
      });
    }
  }, [buyer_name]);

  React.useEffect(() => {
    if (construction !== "") {
      setFilterAction({
        ...filerAction,
        construction: construction ? [construction.construction] : [],
      });
    }
  }, [construction]);

  React.useEffect(() => {
    if (composition !== "") {
      setFilterAction({
        ...filerAction,
        composition: composition ? [composition.composition] : [],
      });
    }
  }, [composition]);

  React.useEffect(() => {
    if (po !== "") {
      setFilterAction({
        ...filerAction,
        po: po ? [po.po] : [],
      });
    }
  }, [po]);

  React.useEffect(() => {
    if (type !== "") {
      setFilterAction({
        ...filerAction,
        type: type ? [type.type] : [],
      });
    }
  }, [type]);

  React.useEffect(() => {
    if (category !== "") {
      setFilterAction({
        ...filerAction,
        category: category ? [category.category] : [],
      });
    }
  }, [category]);

  React.useEffect(() => {
    if (color !== "") {
      setFilterAction({
        ...filerAction,
        color: color ? [color.color] : [],
      });
    }
  }, [color]);

  React.useEffect(() => {
    if (width !== "") {
      setFilterAction({
        ...filerAction,
        width: width ? [width.width] : [],
      });
    }
  }, [width]);

  React.useEffect(() => {
    if (gsm !== "") {
      setFilterAction({
        ...filerAction,
        gsm: gsm ? [gsm.gsm] : [],
      });
    }
  }, [gsm]);

  React.useEffect(() => {
    if (filerAction !== "") {
      fetch(`${API_URL}/api/requisition/report-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(filerAction),
      }).then((response) => {
        response.json().then((result) => {
          console.log("report Data", result);
          setReportData(result.response);
          setReqData(result.request);
          setLoading(false);
        });
      });
    }
  }, [filerAction]);

  React.useEffect(() => {
    if (filerAction !== "") {
      delete filerAction.item_type;
      fetch(`${API_URL}/api/fabric-report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(filerAction),
      }).then((response) => {
        response.json().then((result) => {
          console.log("report Data", result);
          setData(result);
          setLoading(false);
        });
      });
    }
  }, [filerAction]);

  React.useEffect(() => {
    if (filerAction !== "") {
      delete filerAction.item_type;
      fetch(`${API_URL}/api/requisition/fabric-qr-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(filerAction),
      }).then((response) => {
        response.json().then((result) => {
          console.log("QR List", result.response);
          setQrList(result.response);
          setLoading(false);
        });
      });
    }
  }, [filerAction]);

  console.log("reportData", reportData);

  const requisition = useSelector((state) => state.requisition);
  const { requisitions } = requisition;

  React.useEffect(() => {
    dispatch(requisitionList());
  }, [dispatch]);

  const roll_qty_sum = requisitions.reduce(
    (prev, next) => prev + parseInt(next.req_roll_qty),
    0
  );

  const balance = stockCount - roll_qty_sum;

  console.log("balance_roll_qty", roll_qty_sum);

  return (
    <Box sx={{ flexGrow: 1 }} style={{ width: "100%", height: "100%" }}>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={9} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Icon style={{ fontSize: 22, marginTop: 20, color: "#13292A" }}>
                assessment
              </Icon>
              <h3 style={{ marginLeft: 5, color: "#13292A" }}>
                Fab. Requisition
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -30,
                fontSize: 11,
                marginLeft: 25,
                color: "#13292A",
              }}
            >
              <p>Fabrics</p>
              <Icon style={{ fontSize: 14, marginTop: 12 }}>navigate_next</Icon>
              <p>Fab. Requisition</p>
            </div>
          </Grid>
          <Grid item xs={12} md={3} sm={4} align="right">
            <div style={{ marginTop: 25 }}>
              {/* <Button
                variant="outlined"
                startIcon={<PrintIcon />}
                style={{ fontSize: 12, color: "#13292A" }}
                style={{ minWidth: 150, minHeight: 40, marginBottom: 10 }}
                onClick={() => {
                  history.push({
                    pathname: `/app/doc/fabric_reports`,
                    state: data,
                  });
                }}
                // disabled={!insertPermission}
              >
                Print
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid
          container
          xs={12}
          style={{ backgroundColor: "", minHeight: "10vh" }}
        >
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].job_id : []}
              disableCloseOnSelect
              onChange={(e, v) => setJobId(v)}
              getOptionLabel={(option) => option.job_id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.job_id}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Job Id" placeholder="Job Id" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].pi_no : []}
              disableCloseOnSelect
              onChange={(e, v) => setPiNo(v)}
              getOptionLabel={(option) => option.pi_no}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.pi_no}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="PI No." placeholder="PI No." />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].buyer_name : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.buyer_name}
              onChange={(e, v) => setBuyerName(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.buyer_name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Buyer Name"
                  placeholder="Select Buyer"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].style : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.style}
              onChange={(e, v) => setStyle(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.style}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Style" placeholder="Style" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].po : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.po}
              onChange={(e, v) => setPo(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.po}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="PO No." placeholder="PO No." />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].construction : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.construction}
              onChange={(e, v) => setConstruction(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.construction}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Construction"
                  placeholder="Construction"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].composition : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.composition}
              onChange={(e, v) => setComposition(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.composition}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Composition"
                  placeholder="Composition"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].type : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.type}
              onChange={(e, v) => setType(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.type}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Type" placeholder="Type" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].category : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.category}
              onChange={(e, v) => setCategory(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.category}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  placeholder="Category"
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].color : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.color}
              onChange={(e, v) => setColor(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.color}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Color" placeholder="Color" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].width : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.width}
              onChange={(e, v) => setWidth(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.width}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Width" placeholder="Width" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].gsm : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.gsm}
              onChange={(e, v) => setGsm(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.gsm}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="GSM" placeholder="GSM" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <TextField
              name="date"
              id="date"
              label="Shipment Date"
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              size="small"
              onChange={(e) => set_shipment_date(e.target.value)}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].shrinkage_length : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.shrinkage_length}
              onChange={(e, v) => setShrinkageLength(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.shrinkage_length}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Shrinkage in Length"
                  placeholder="Shrinkage in Length"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={
                filerParameter.length ? filerParameter[0].shrinkage_width : []
              }
              disableCloseOnSelect
              getOptionLabel={(option) => option.shrinkage_width}
              onChange={(e, v) => setShrinkageWidth(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.shrinkage_width}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Shrinkage in Width"
                  placeholder="Shrinkage in Width"
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].pattern : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.pattern}
              onChange={(e, v) => setPattern(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.pattern}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Pattern" placeholder="Pattern" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].shade : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.shade}
              onChange={(e, v) => setShade(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.shade}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Shade" placeholder="Shade" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2.4}
            style={{ backgroundColor: "", minHeight: "5vh", padding: 10 }}
          >
            <Autocomplete
              id="size-small-outlined-multi"
              size="small"
              options={filerParameter.length ? filerParameter[0].others : []}
              disableCloseOnSelect
              getOptionLabel={(option) => option.others}
              onChange={(e, v) => setOthers(v)}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.others}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Others" placeholder="Others" />
              )}
            />
          </Grid>
        </Grid>
        <>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                minWidth: "100vw",
                marginTop: 50,
                minHeight: "60vh",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div style={{ minHeight: "60vh", minWidth: "100%" }}>
              <Grid
                container
                xs={12}
                style={{
                  marginTop: 30,
                  border: "1px solid grey",
                }}
                justify="center"
              >
                <Grid
                  item
                  xs={1}
                  align="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "green",
                  }}
                >
                  <text
                    style={{
                      fontSize: 24,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    Stock <br />
                    {reportData.length ? stockCount : reportData.length}
                  </text>
                </Grid>
                {reportData.length ? (
                  <Grid item xs={11}>
                    <OutlinedCard
                      reportData={reportData}
                      setStockCount={setStockCount}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid
                container
                xs={12}
                style={{
                  marginTop: 30,
                }}
                justify="center"
              >
                <Grid item xs={8} align="center" style={{}}>
                  <Table
                    reportData={reportData}
                    reqData={reqData}
                    qrList={qrList}
                    stockCount={stockCount}
                  />
                </Grid>
                <Grid item xs={4} align="center" style={{ padding: 20 }}>
                  <div style={{ border: "1px black solid" }}>
                    <div
                      style={{
                        minHeight: 50,
                        borderBottom: "1px black solid",
                        backgroundColor: "green",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <text
                        style={{
                          fontSize: 24,
                          fontWeight: "bold",
                          color: "white",
                          textAlign: "center",
                        }}
                      >
                        Balance
                      </text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          height: 50,
                          width: "50%",
                          borderRight: "1px black solid",
                          borderBottom: "1px black solid",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(24,148,2,255",
                        }}
                      >
                        <text
                          style={{
                            fontSize: 18,
                            color: "white",
                          }}
                        >
                          Roll Qty
                        </text>
                      </div>
                      <div
                        style={{
                          height: 50,
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          borderBottom: "1px black solid",
                          alignItems: "center",
                        }}
                      >
                        <text
                          style={{
                            fontSize: 18,
                            color: "black",
                          }}
                        >
                          {balance}
                        </text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          height: 50,
                          width: "50%",
                          display: "flex",
                          borderRight: "1px black solid",

                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(24,148,2,255",
                        }}
                      >
                        <text
                          style={{
                            fontSize: 18,

                            color: "white",
                            textAlign: "center",
                          }}
                        >
                          Yds Qty
                        </text>
                      </div>
                      <div
                        style={{
                          height: 50,
                          width: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <text
                          style={{
                            fontSize: 18,
                            color: "black",
                          }}
                        >
                          {(stockCount - roll_qty_sum) * reportData.length
                            ? reportData[0].conv_rate
                            : 1}
                        </text>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      </Grid>
    </Box>
  );
};

export default Index;

import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fabricList,
  fabricUpdateAction,
} from "../../../redux/actions/fabricAction";
import { EditValidation } from "./FormValidation";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    position: "absolute",
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>shopping_bag</Icon>
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    fabrics,
    measuringUnits,
    setNotify,
    openEditPopup,
    setOpenEditPopup,
    item,
  } = props;
  const [uom_qty, setUomqty] = React.useState("");
  const [uom_tracking, setUomtracking] = React.useState("");
  const [job_date, set_job_date] = React.useState("");
  const [shipment_date, set_shipment_date] = React.useState("");
  const [status, setStatus] = React.useState("Y");
  const [getCategory, setCategory] = React.useState({
    _id: "",
    category: "",
  });

  const id = item.id;

  const device_code = "123456";
  const device_validation = "Y";

  useEffect(() => {
    setUomqty(item.uom_qty);
    setUomtracking(item.uom_tracking);
    set_job_date(item.job_date);
    set_shipment_date(item.shipment_date);
    setStatus(item.status);
    setCategory({
      _id: item.id,
      category: item.category,
    });
  }, [item]);

  const dispatch = useDispatch();
  const fabricUpdate = useSelector((state) => state.fabricUpdate);
  const { fabricUpdates } = fabricUpdate;

  console.log("data", fabricUpdates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("buyer_name" in fieldValues)
      temp.buyer_name = fieldValues.buyer_name ? "" : "This field is required.";

    if ("type" in fieldValues)
      temp.type = fieldValues.type ? "" : "This field is required.";

    if ("color" in fieldValues)
      temp.color = fieldValues.color ? "" : "This field is required.";

    if ("width" in fieldValues)
      temp.width = fieldValues.width ? "" : "This field is required.";

    if ("style" in fieldValues)
      temp.style = fieldValues.style ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (getCategory.category === "") {
      setCategory({
        _id: "",
        category: "",
        error: true,
      });
    }

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);
  console.log("oreds", values);

  const job_id = values.job_id;
  const order_item_id = values.order_item_id;
  const tracking_qty = values.lot_qty_roll;
  const buyer_name = values.buyer_name;
  const style = values.style;
  const po = values.po;
  const pi_no = values.pi_no;
  const type = values.type;
  const category = getCategory.category;
  const composition = values.composition;
  const construction = values.construction;
  const width = values.width;
  const color = values.color;
  const gsm = values.gsm;
  // const uom_tracking = values.uom_tracking;
  const order_qty = values.order_qty_roll;
  // const uom_qty =values.uom_qty;
  const item_type = "Fabrics";
  const conversion_rate = values.order_qty_yds / values.order_qty_roll;
  const lot_conversion_rate = values.lot_qty_yds / values.lot_qty_roll;
  const lot_no = values.lot_no;
  const others = values.others;
  const remarks = values.remarks;
  const shrinkage_length = values.shrinkage_length;
  const shrinkage_width = values.shrinkage_width;
  const pattern = values.pattern;
  const shade = values.shade;

  console.log(
    "editData",
    id,
    order_item_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    job_id
  );

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate() && getCategory.category !== "") {
      dispatch(
        fabricUpdateAction(
          item.id,
          order_item_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          pi_no,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks,
          job_id,
          lot_no,
          lot_conversion_rate,
          shrinkage_length,
          shrinkage_width,
          pattern,
          shade
        )
      );
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(fabricList());
      }, 2000);
      // setNotify({
      //   isOpen: true,
      //   message: 'Submitted Successfully!',
      //   type: 'success',
      // });
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  useEffect(() => {
    if (values.category !== "") {
      setCategory({
        _id: item.id,
        category: values.category,
      });
    }
  }, [values]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      onClose={handleClose}
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Modify Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          setUomtracking={setUomtracking}
          setUomqty={setUomqty}
          fabrics={fabrics}
          measuringUnits={measuringUnits}
          item={item}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          set_job_date={set_job_date}
          status={status}
          setStatus={setStatus}
          setCategory={setCategory}
          getCategory={getCategory}
          shipment_date={shipment_date}
          set_shipment_date={set_shipment_date}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

import axios from "axios";

import {
  TRACKING_CODES_LIST_REQUEST,
  TRACKING_CODES_LIST_SUCCESS,
  TRACKING_CODES_LIST_FAIL,
  TRACKING_CODES_CREATE_REQUEST,
  TRACKING_CODES_CREATE_SUCCESS,
  TRACKING_CODES_CREATE_FAIL,
  TRACKING_CODES_UPDATE_REQUEST,
  TRACKING_CODES_UPDATE_SUCCESS,
  TRACKING_CODES_UPDATE_FAIL,
  TRACKING_CODES_DELETE_REQUEST,
  TRACKING_CODES_DELETE_SUCCESS,
  TRACKING_CODES_DELETE_FAIL,
} from "../constants/trackingCodesConstant";
import API_URL from "../../utils/api";

export const trackingCodesList = () => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_CODES_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/tracking-codes`, config);

    dispatch({
      type: TRACKING_CODES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_CODES_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const trackingCodesListFix = (item_type) => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_CODES_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      `${API_URL}/api/tracking-codes-fix`,
      item_type,
      config
    );

    dispatch({
      type: TRACKING_CODES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_CODES_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const trackingCodesCreateAction =
  (tracking_job_id) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_CODES_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/tracking-codes`,
        { tracking_job_id },
        config
      );

      console.log("data23", data);

      dispatch({
        type: TRACKING_CODES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_CODES_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingCodesUpdateAction =
  (id, printable) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_CODES_UPDATE_REQUEST,
      });

      console.log("id", id);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `${API_URL}/api/tracking-codes/${id}`,
          {
            printable,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      dispatch({
        type: TRACKING_CODES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_CODES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingCodesAddlUpdateAction =
  (id, shrinkage_length, shrinkage_width, pattern, shade) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_CODES_UPDATE_REQUEST,
      });

      console.log("id", id);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `${API_URL}/api/tracking-codes/${id}`,
          {
            shrinkage_length,
            shrinkage_width,
            pattern,
            shade,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      dispatch({
        type: TRACKING_CODES_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_CODES_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

// export const trackingJobsDeleteAction = (id) => async (dispatch) => {
//   try {
//     dispatch({
//       type: TRACKING_JOBS_DELETE_REQUEST,
//     });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       },
//     };

//     console.log("id", id);

//     await axios.delete(
//       `http://tracking.clonestudiobd.com/api/tracking-jobs/${id}`,
//       config
//     );

//     dispatch({
//       type: TRACKING_JOBS_DELETE_SUCCESS,
//     });
//   } catch (error) {
//     dispatch({
//       type: TRACKING_JOBS_DELETE_FAIL,
//       payload:
//         error.response && error.response.data.error
//           ? error.response.data.error
//           : error.error,
//     });
//   }
// };

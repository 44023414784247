export const REQUISITION_REQUEST = "REQUISITION_REQUEST";
export const REQUISITION_SUCCESS = "REQUISITION_SUCCESS";
export const REQUISITION_FAIL = "REQUISITION_FAIL";

export const REQUISITION_CREATE_REQUEST = "REQUISITION_CREATE_REQUEST";
export const REQUISITION_CREATE_SUCCESS = "REQUISITION_CREATE_SUCCESS";
export const REQUISITION_CREATE_FAIL = "REQUISITION_CREATE_FAIL";
export const REQUISITION_CREATE_RESET = "REQUISITION_CREATE_RESET";

export const REQUISITION_UPDATE_REQUEST = "REQUISITION_UPDATE_REQUEST";
export const REQUISITION_UPDATE_SUCCESS = "REQUISITION_UPDATE_SUCCESS";
export const REQUISITION_UPDATE_FAIL = "REQUISITION_UPDATE_FAIL";
export const REQUISITION_UPDATE_RESET = "REQUISITION_UPDATE_RESET";

export const REQUISITION_DELETE_REQUEST = "REQUISITION_DELETE_REQUEST";
export const REQUISITION_DELETE_SUCCESS = "REQUISITION_DELETE_SUCCESS";
export const REQUISITION_DELETE_FAIL = "REQUISITION_DELETE_FAIL";

import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import {
  userLoginReducer,
  userListReducer,
  userCreateReducer,
  userUpdateReducer,
  userDeleteReducer,
} from "./reducers/userReducer";

import {
  measuringUnitReducer,
  measuringUnitCreateReducer,
  measuringUnitUpdateReducer,
  measuringUnitDeleteReducer,
} from "./reducers/measuringUnitReducer";

import {
  trackingPurposesReducer,
  trackingPurposesCreateReducer,
  trackingPurposesUpdateReducer,
  trackingPurposesDeleteReducer,
} from "./reducers/trackingPurposesReducers";

import {
  trackingLocationReducer,
  trackingLocationCreateReducer,
  trackingLocationUpdateReducer,
  trackingLocationDeleteReducer,
} from "./reducers/trackingLocationReducer";

import {
  trackingJobsListReducer,
  trackingJobsCreateReducer,
  trackingJobsUpdateReducer,
  trackingJobsDeleteReducer,
} from "./reducers/trackingJobsReducer";

import {
  trackingCodesListReducer,
  trackingCodesCreateReducer,
  trackingCodesUpdateReducer,
  trackingCodesDeleteReducer,
} from "./reducers/trackingCodesReducer";

import {
  cartoonReducer,
  cartoonCreateReducer,
  cartoonUpdateReducer,
  cartoonDeleteReducer,
} from "./reducers/cartoonReducer";

import {
  fabricReducer,
  fabricCreateReducer,
  fabricUpdateReducer,
  fabricDeleteReducer,
} from "./reducers/fabricReducer";

import {
  requisitionReducer,
  requisitionCreateReducer,
  requisitionUpdateReducer,
  requisitionDeleteReducer,
} from "./reducers/requisitionReducer";

import { permissionReducer } from "./reducers/permissionReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  user: userListReducer,
  userCreate: userCreateReducer,
  userUpdate: userUpdateReducer,
  userDelete: userDeleteReducer,
  permission: permissionReducer,
  measuringUnit: measuringUnitReducer,
  measuringUnitCreate: measuringUnitCreateReducer,
  measuringUnitUpdate: measuringUnitUpdateReducer,
  measuringUnitDelete: measuringUnitDeleteReducer,
  trackingPurposes: trackingPurposesReducer,
  trackingPurposesCreate: trackingPurposesCreateReducer,
  trackingPurposesUpdate: trackingPurposesUpdateReducer,
  trackingPurposesDelete: trackingPurposesDeleteReducer,
  location: trackingLocationReducer,
  locationCreate: trackingLocationCreateReducer,
  locationUpdate: trackingLocationUpdateReducer,
  locationDelete: trackingLocationDeleteReducer,
  trackingJob: trackingJobsListReducer,
  trackingJobCreate: trackingJobsCreateReducer,
  trackingJobUpdate: trackingJobsUpdateReducer,
  trackingJobDelete: trackingJobsDeleteReducer,
  trackingCode: trackingCodesListReducer,
  trackingCodeCreate: trackingCodesCreateReducer,
  trackingCodeUpdate: trackingCodesUpdateReducer,
  trackingCodeDelete: trackingCodesDeleteReducer,
  cartoon: cartoonReducer,
  cartoonCreate: cartoonCreateReducer,
  cartoonUpdate: cartoonUpdateReducer,
  cartoonDelete: cartoonDeleteReducer,
  fabric: fabricReducer,
  fabricCreate: fabricCreateReducer,
  fabricUpdate: fabricUpdateReducer,
  fabricDelete: fabricDeleteReducer,
  requisition: requisitionReducer,
  requisitionCreate: requisitionCreateReducer,
  requisitionUpdate: requisitionUpdateReducer,
  requisitionDelete: requisitionDeleteReducer,
});

const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const store = createStore(
  reducer,
  initialState,
  applyMiddleware(...middleware)
);

export default store;

export const TRACKING_LOCATION_REQUEST = "TRACKING_LOCATION_REQUEST";
export const TRACKING_LOCATION_SUCCESS = "TRACKING_LOCATION_SUCCESS";
export const TRACKING_LOCATION_FAIL = "TRACKING_LOCATION_FAIL";

export const TRACKING_LOCATION_CREATE_REQUEST = "TRACKING_LOCATION_CREATE_REQUEST";
export const TRACKING_LOCATION_CREATE_SUCCESS = "TRACKING_LOCATION_CREATE_SUCCESS";
export const TRACKING_LOCATION_CREATE_FAIL = "TRACKING_LOCATION_CREATE_FAIL";
export const TRACKING_LOCATION_CREATE_RESET = "TRACKING_LOCATION_CREATE_RESET";

export const TRACKING_LOCATION_UPDATE_REQUEST = "TRACKING_LOCATION_UPDATE_REQUEST";
export const TRACKING_LOCATION_UPDATE_SUCCESS = "TRACKING_LOCATION_UPDATE_SUCCESS";
export const TRACKING_LOCATION_UPDATE_FAIL = "TRACKING_LOCATION_UPDATE_FAIL";
export const TRACKING_LOCATION_UPDATE_RESET = "TRACKING_LOCATION_UPDATE_RESET";

export const TRACKING_LOCATION_DELETE_REQUEST = "TRACKING_LOCATION_DELETE_REQUEST";
export const TRACKING_LOCATION_DELETE_SUCCESS = "TRACKING_LOCATION_DELETE_SUCCESS";
export const TRACKING_LOCATION_DELETE_FAIL = "TRACKING_LOCATION_DELETE_FAIL";
import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import OutlinedCard from "../ReportCard";
import { useDispatch, useSelector } from "react-redux";
import { fabricList } from "../../../../redux/actions/fabricAction";
import reactDom from "react-dom";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Index = () => {
  const dispatch = useDispatch();
  const [reportData, setReportData] = React.useState("");
  const [data, setData] = React.useState("");
  const [job_id, setJobId] = React.useState("");
  const [style, setStyle] = React.useState("");

  React.useEffect(() => {
    fetch(
      "http://tracking.clonestudiobd.com/api/tracking-locations/report-data",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ item_type: "Carton" }),
      }
    ).then((response) => {
      response.json().then((result) => {
        console.log("Checking user Data", result);
        setReportData(result);
      });
    });
  }, [dispatch]);

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  function mapToProp(data, prop) {
    var newData = data
      ? data.reduce(
          (res, item) =>
            Object.assign(res, {
              [item[prop]]: 1 + (res[item[prop]] || 0),
            }),
          Object.create(null)
        )
      : null;

    return Object.entries(newData ? newData : []).map(
      ([location_name, count]) => ({
        location_name,
        count,
      })
    );
  }

  var filteredJobID = removeDuplicates(reportData, "job_id");
  var filteredStyle = removeDuplicates(reportData, "style");
  var filteredColor = removeDuplicates(reportData, "color");
  var filteredType = removeDuplicates(reportData, "type");
  var filteredConstruction = removeDuplicates(reportData, "construction");
  var filteredComposition = removeDuplicates(reportData, "composition");
  var filteredCategory = removeDuplicates(reportData, "category");

  const filteredRackByJobId =
    data.length && job_id
      ? data
          .filter((item) => {
            return job_id.some((item2) => item2.job_id === item.job_id);
          })
          .map((item) => item)
      : reportData.length && job_id
      ? reportData
          .filter((item) => {
            return job_id.some((item2) => item2.job_id === item.job_id);
          })
          .map((item) => item)
      : [];

  const filteredRackByStyle =
    reportData && style
      ? reportData
          .filter((item) => {
            return style.some((item2) => item2.job_id === item.job_id);
          })
          .map((item) => item)
      : null;

  //card-content
  var filteredReportDataLocation = removeDuplicates(
    filteredRackByJobId,
    "location_name"
  );

  useEffect(() => {
    if (filteredRackByJobId.length) {
      setData(filteredRackByJobId);
    }
  }, [filteredRackByJobId]);

  var allLocation = removeDuplicates(reportData, "location_name");
  var jobIdCardData = mapToProp(filteredRackByJobId, "location_name");

  console.log("filteredReportDataLocation", filteredReportDataLocation);
  console.log("job_id", job_id);
  console.log("filteredRackByJobId", filteredRackByJobId);
  console.log("jobIdCardData", jobIdCardData);
  console.log("allLocation", allLocation);
  console.log("data", data);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid
          container
          xs={12}
          style={{ backgroundColor: "", minHeight: "30vh" }}
        >
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredJobID}
              disableCloseOnSelect
              onChange={(e, v) => setJobId(v)}
              getOptionLabel={(option) => option.job_id}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.job_id}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Job Id" placeholder="Job Id" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredStyle}
              disableCloseOnSelect
              getOptionLabel={(option) => option.style}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.style}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Style" placeholder="Style" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredColor}
              disableCloseOnSelect
              getOptionLabel={(option) => option.color}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.color}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Color" placeholder="Color" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredType}
              disableCloseOnSelect
              getOptionLabel={(option) => option.type}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.type}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Type" placeholder="Type" />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredConstruction}
              disableCloseOnSelect
              getOptionLabel={(option) => option.construction}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.construction}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Construction"
                  placeholder="Construction"
                />
              )}
            />
          </Grid>
          {/* <Grid
            item
            xs={4}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredComposition}
              disableCloseOnSelect
              getOptionLabel={(option) => option.composition}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.composition}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Composition"
                  placeholder="Composition"
                />
              )}
            />
          </Grid> */}
          <Grid
            item
            xs={4}
            style={{ backgroundColor: "", minHeight: "15vh", padding: 10 }}
          >
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={filteredCategory}
              disableCloseOnSelect
              getOptionLabel={(option) => option.category}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.category}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  placeholder="Category"
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          style={{ backgroundColor: "", minHeight: "50vh" }}
        >
          <OutlinedCard
            jobIdCardData={jobIdCardData.length ? jobIdCardData : allLocation}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Index;

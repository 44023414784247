import * as React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";

import { Avatar } from "@mui/material";
import { deepOrange, green } from "@mui/material/colors";
import { Menu, MenuItem, Button } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MoreIcon from "@mui/icons-material/MoreVert";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../assets/images/logo.png";
import Fabrics from "../assets/images/01.svg";
import Cartons from "../assets/images/02.svg";
import Accessories from "../assets/images/03.svg";
import Reports from "../assets/images/04.svg";
import Settings from "../assets/images/05.svg";

import FabricsIcon from "../assets/images/Fabrics.svg";
import CartonsIcon from "../assets/images/Cartons.svg";
import AccessoriesIcon from "../assets/images/Accessories.svg";
import ReportsIcon from "../assets/images/Reports.svg";
import SettingsIcon from "../assets/images/Settings.svg";
import QRCodeScan from "../assets/images/qr_code_black_24dp.svg";
import RequisitionIcon from "../assets/images/requisition.svg";
import EntryIcon from "../assets/images/entry.svg";
import AddlInfoIcon from "../assets/images/addlfab.svg";
import UserIcon from "../assets/images/groups_black_24dp.svg";
import TrackingPurposeIcon from "../assets/images/local_offer_black_24dp.svg";
import TrackingLocationIcon from "../assets/images/my_location_black_24dp.svg";
import UOMIcon from "../assets/images/assessment_black_24dp.svg";
import { useHistory, Link } from "react-router-dom";

import { logout } from "../redux/actions/userActions";
import { useDispatch, useSelector } from "react-redux";

const menus = [
  {
    id: 1,
    menu_name: "Fabrics",
    menu_image: Fabrics,
    menu_link: "/",
    children: [
      {
        id: 1,
        menu_name: "New Fab. Entry",
        menu_link: "/fabrics_new_consignment",
        menu_icon: EntryIcon,
      },
      {
        id: 2,
        menu_name: "Create QR Code",
        menu_link: "/fabrics_qr_code",
        menu_icon: QRCodeScan,
      },
      {
        id: 3,
        menu_name: "Addl. Fab. Info",
        menu_link: "/additional_fab_info",
        menu_icon: AddlInfoIcon,
      },
      {
        id: 4,
        menu_name: "Fab. Requisition",
        menu_link: "/fab_requisition",
        menu_icon: RequisitionIcon,
      },
    ],
  },
  {
    id: 2,
    menu_name: "Cartons",
    menu_image: Cartons,
    menu_link: "/",
    children: [
      {
        id: 1,
        menu_name: "Ctn. New Entry",
        menu_link: "/cartons_new_consignment",
        menu_icon: Cartons,
      },
      {
        id: 2,
        menu_name: "Generate Ctn. QR",
        menu_link: "/cartons_qr_code",
        menu_icon: QRCodeScan,
      },
    ],
  },
  {
    id: 3,
    menu_name: "Accessories",
    menu_image: Accessories,
    menu_link: "/",
    children: [
      {
        id: 1,
        menu_name: "Accss. New Entry",
        menu_link: "/accessories_new_consignment",
        menu_icon: Accessories,
      },
      {
        id: 2,
        menu_name: "Generate Accss. QR",
        menu_link: "/accessories_qr_code",
        menu_icon: QRCodeScan,
      },
    ],
  },
  {
    id: 4,
    menu_name: "Reports",
    menu_image: Reports,
    menu_link: "/",
    children: [
      {
        id: 1,
        menu_name: "Fabric Reports",
        menu_link: "/fabric_reports",
        menu_icon: UOMIcon,
      },
      {
        id: 2,
        menu_name: "Fab. Stock Status",
        menu_link: "/fab_stock_status",
        menu_icon: UOMIcon,
      },
      {
        id: 2,
        menu_name: "Carton Reports",
        menu_link: "/carton_reports",
        menu_icon: UOMIcon,
      },
    ],
  },
  {
    id: 5,
    menu_name: "Settings",
    menu_image: Settings,
    menu_link: "/",
    children: [
      {
        id: 1,
        menu_name: "Users",
        menu_link: "/users",
        menu_icon: UserIcon,
      },
      {
        id: 2,
        menu_name: "Purposes",
        menu_link: "/purposes",
        menu_icon: TrackingPurposeIcon,
      },
      {
        id: 3,
        menu_name: "Bin Numbers",
        menu_link: "/bin_numbers",
        menu_icon: TrackingLocationIcon,
      },
      {
        id: 4,
        menu_name: "UOM",
        menu_link: "/uom",
        menu_icon: UOMIcon,
      },
    ],
  },
];

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    minWidth: 250,
    minHeight: -10,
    marginTop: theme.spacing(1),
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&:hover": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
        color: "#1d2357",
        fontWeight: "500",
      },
    },
  },
}));

export default function ProminentAppBar(props) {
  const { userData } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const match = useMediaQuery("max-device-width: 480px");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEll, setAnchorEll] = React.useState(null);
  const [getId, setId] = React.useState("");

  const open = Boolean(anchorEl);
  const openLogout = Boolean(anchorEll);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAvatarClick = (event) => {
    setAnchorEll(event.currentTarget);
  };
  const handleAvatarClose = () => {
    setAnchorEll(null);
  };

  const handleSetId = (item) => {
    setId(item);
  };

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload();
  };

  const nameIcon = userData ? userData.name.charAt(0).toUpperCase() : null;
  const userName = userData ? userData.name : null;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar id="app-bar" position="fixed" className="not-scrolled">
        {matches ? (
          <div
            style={{
              minHeight: 130,
              backgroundColor: "rgba(24,148,2,255)",
              boxShadow: "1px 1px 2px grey",
            }}
          >
            <div style={{ height: 5, backgroundColor: "red" }}></div>
            <div style={{ height: 70 }}>
              <Grid container justify="center" style={{ height: "100%" }}>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  justify="center"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      variant="rounded"
                      alt="logo"
                      src={logo}
                      style={{
                        height: 60,
                        width: 60,
                        marginLeft: 50,
                      }}
                    />
                    <text
                      style={{
                        fontWeight: "500",
                        fontSize: 14,
                        color: "white",
                        marginLeft: 20,
                        textShadow: "2px 2px 2px black",
                        textAlign: "left",
                      }}
                    >
                      <Link
                        to="/app"
                        color="inherit"
                        style={{
                          textDecoration: "none",
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <text style={{ color: "yellow", fontSize: 15 }}>
                          M
                          <text style={{ color: "white", fontSize: 14 }}>
                            aterials
                          </text>
                        </text>
                        <text style={{ color: "yellow", fontSize: 15 }}>
                          T
                          <text style={{ color: "white", fontSize: 14 }}>
                            racking
                          </text>
                        </text>
                        <text style={{ color: "yellow", fontSize: 15 }}>
                          S
                          <text style={{ color: "white", fontSize: 14 }}>
                            ystem
                          </text>
                        </text>
                      </Link>
                    </text>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  align="center"
                  justify="center"
                  style={{ height: "100%" }}
                  direction="column"
                >
                  {menus
                    ? menus.map((item) => {
                        return (
                          <Grid item xs={12 / menus.length} style={{}}>
                            <div>
                              <Button
                                variant="text"
                                color="info"
                                component={
                                  item.menu_link === "/" ? Typography : Link
                                }
                                to={{
                                  pathname: `/app${item.menu_link}`,
                                  state: {
                                    item_icon: item.menu_icon,
                                  },
                                }}
                                aria-expanded={open ? "true" : undefined}
                                disableElevation
                                onClick={(event) => {
                                  if (item.children !== undefined) {
                                    handleClick(event);
                                    handleSetId(item);
                                  }
                                }}
                                style={{
                                  marginRight: 20,
                                  textTransform: "none",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={item.menu_image}
                                  alt="Italian Trulli"
                                  style={{
                                    height: 90,
                                    width: 80,
                                  }}
                                />

                                <Typography
                                  component="div"
                                  style={{
                                    fontWeight: "bold",
                                    color: "black",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {item.menu_name}{" "}
                                  {item.children.length ? (
                                    <KeyboardArrowDownIcon
                                      color="#6gh655"
                                      fontSize={"small"}
                                      style={{ marginLeft: 10 }}
                                    />
                                  ) : null}
                                </Typography>
                              </Button>
                              {item.children.length && getId.id === item.id ? (
                                <StyledMenu
                                  id="demo-customized-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "demo-customized-button",
                                  }}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                >
                                  {item.children
                                    ? item.children.map((item) => {
                                        return (
                                          <MenuItem
                                            onClick={handleClose}
                                            component={Link}
                                            to={`/app${item.menu_link}`}
                                          >
                                            <Icon
                                              style={{
                                                fontSize: 30,
                                                marginRight: 10,
                                                fontWeight: "bold",
                                                color: "black",
                                              }}
                                            >
                                              <img src={item.menu_icon} />
                                            </Icon>
                                            {item.menu_name}
                                          </MenuItem>
                                        );
                                      })
                                    : null}
                                </StyledMenu>
                              ) : null}
                            </div>
                          </Grid>
                        );
                      })
                    : null}
                </Grid>
                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  justify="center"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    align="center"
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        height: 46,
                        width: "71%",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        borderTopLeftRadius: 25,
                        borderBottomLeftRadius: 25,
                      }}
                    >
                      <div
                        style={{
                          height: 40,
                          width: "99%",
                          backgroundColor: "red",
                          borderTopLeftRadius: 25,
                          borderBottomLeftRadius: 25,
                        }}
                      >
                        <text
                          style={{
                            color: "white",
                            fontWeight: "500",
                            fontSize: 25,
                            textShadow: "2px 2px 2px black",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            marginRight: 30,
                          }}
                        >
                          Laila Group
                        </text>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                height: 60,
                backgroundColor: "white",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <text style={{ color: "black", marginRight: 10, fontSize: 14 }}>
                {userName}
              </text>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
                onClick={(event) => {
                  handleAvatarClick(event);
                }}
              >
                <Avatar style={{ backgroundColor: "red", marginRight: 2 }}>
                  {nameIcon}
                </Avatar>
                <Icon sx={{ color: green[500], fontSize: "medium" }}>
                  keyboard_arrow_down
                </Icon>
              </div>
            </div>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEll}
              open={openLogout}
              onClose={handleAvatarClose}
            >
              <MenuItem onClick={handleAvatarClose}>
                <Typography color="primary" onClick={logoutHandler}>
                  Sign Out
                </Typography>
              </MenuItem>
            </StyledMenu>
          </div>
        ) : (
          <div
            style={{
              minHeight: mobileMatches ? 200 : 250,
              minWidth: "10vw",
              backgroundColor: "rgba(24,148,2,255)",
              boxShadow: "1px 1px 2px grey",
              position: "static",
            }}
          >
            <div style={{ height: mobileMatches ? 140 : 190 }}>
              <Grid container justify="center" style={{ height: "100%" }}>
                <Grid
                  container
                  xs={8}
                  sm={8}
                  md={3}
                  lg={3}
                  justify="center"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <Grid
                    item
                    align="center"
                    xs={12}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      marginTop: mobileMatches ? 10 : 30,
                    }}
                  >
                    <Avatar
                      variant="rounded"
                      alt="logo"
                      src={logo}
                      style={{
                        height: mobileMatches ? 40 : 80,
                        width: mobileMatches ? 40 : 80,
                        marginLeft: 20,
                      }}
                    />
                    <text
                      style={{
                        fontWeight: "bold",
                        fontSize: mobileMatches ? 16 : 24,
                        color: "yellow",
                        marginLeft: 50,
                        textShadow: "2px 2px 2px black",
                        marginTop: 10,
                      }}
                    >
                      <text>
                        M
                        <text
                          style={{
                            color: "white",
                            fontSize: mobileMatches ? 14 : 22,
                          }}
                        >
                          aterials
                        </text>
                      </text>{" "}
                      <text>
                        T
                        <text
                          style={{
                            color: "white",
                            fontSize: mobileMatches ? 14 : 22,
                          }}
                        >
                          racking
                        </text>
                      </text>{" "}
                      <text>
                        S
                        <text
                          style={{
                            color: "white",
                            fontSize: mobileMatches ? 14 : 22,
                          }}
                        >
                          ystem
                        </text>
                      </text>{" "}
                    </text>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={4}
                  sm={4}
                  md={3}
                  lg={3}
                  justify="center"
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Grid
                    item
                    align="center"
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      justifyContent: "flex-start",
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        height: 46,
                        width: "80%",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        borderTopLeftRadius: 25,
                        borderBottomLeftRadius: 25,
                      }}
                    >
                      <div
                        style={{
                          height: 40,
                          width: "98%",
                          backgroundColor: "red",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderTopLeftRadius: 25,
                          borderBottomLeftRadius: 25,
                        }}
                      >
                        <text
                          style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: mobileMatches ? 16 : 25,
                            textShadow: "2px 2px 2px black",
                          }}
                        >
                          Laila Group
                        </text>
                      </div>
                    </div>
                    <Avatar
                      sx={{
                        bgcolor: deepOrange[500],
                        marginRight: mobileMatches ? 0.5 : 2,
                        marginTop: mobileMatches ? 0.5 : 2,
                      }}
                      onClick={(event) => {
                        handleAvatarClick(event);
                      }}
                    >
                      {nameIcon}
                    </Avatar>
                    <StyledMenu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEll}
                      open={openLogout}
                      onClose={handleAvatarClose}
                    >
                      <MenuItem onClick={handleAvatarClose}>
                        <Typography color="primary" onClick={logoutHandler}>
                          Sign Out
                        </Typography>
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                </Grid>
              </Grid>
            </div>
            <div
              style={{
                height: 60,
                backgroundColor: "white",
              }}
            >
              <Grid
                container
                xs={12}
                sm={12}
                md={6}
                lg={6}
                align="center"
                justify="center"
                style={{ height: "100%" }}
              >
                {menus
                  ? menus.map((item) => {
                      return (
                        <Grid
                          item
                          xs={12 / menus.length}
                          style={{
                            height: "100%",
                            position: "relative",
                            marginTop: mobileMatches ? -40 : -65,
                          }}
                        >
                          <div>
                            <Button
                              variant="text"
                              color="info"
                              component={
                                item.menu_link === "/" ? Typography : Link
                              }
                              to={{
                                pathname: `/app${item.menu_link}`,
                                state: {
                                  item_icon: item.menu_icon,
                                },
                              }}
                              aria-expanded={open ? "true" : undefined}
                              disableElevation
                              onClick={(event) => {
                                if (item.children !== undefined) {
                                  handleClick(event);
                                  handleSetId(item);
                                }
                              }}
                              style={{
                                textTransform: "none",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={item.menu_image}
                                alt="Italian Trulli"
                                style={{
                                  height: mobileMatches ? 55 : 90,
                                  width: mobileMatches ? 45 : 80,
                                }}
                              />

                              <Typography
                                component="p"
                                style={{
                                  fontWeight: "bold",
                                  color: "black",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  fontSize: 10,
                                }}
                              >
                                {item.menu_name}{" "}
                                {item.children.length ? (
                                  <KeyboardArrowDownIcon
                                    color="#6gh655"
                                    style={{
                                      fontSize: mobileMatches ? 14 : 20,
                                      marginLeft: mobileMatches ? 2 : 10,
                                    }}
                                  />
                                ) : null}
                              </Typography>
                            </Button>
                            {item.children.length && getId.id === item.id ? (
                              <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                  "aria-labelledby": "demo-customized-button",
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                              >
                                {item.children
                                  ? item.children.map((item) => {
                                      return (
                                        <MenuItem
                                          onClick={handleClose}
                                          component={Link}
                                          to={`/app${item.menu_link}`}
                                        >
                                          <Icon
                                            style={{
                                              fontSize: 30,
                                              marginRight: 10,
                                              fontWeight: "bold",
                                              color: "black",
                                            }}
                                          >
                                            <img src={item.menu_icon} />
                                          </Icon>
                                          {item.menu_name}
                                        </MenuItem>
                                      );
                                    })
                                  : null}
                              </StyledMenu>
                            ) : null}
                          </div>
                        </Grid>
                      );
                    })
                  : null}
              </Grid>
            </div>
          </div>
        )}
      </AppBar>
    </Box>
  );
}

import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Grid from "@mui/material/Grid";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function OutlinedCard(props) {
  const { reportData } = props;
  return (
    <Grid container style={{}}>
      {reportData.length
        ? reportData.map((item) => {
            return (
              <Grid item xs={1.2} style={{ padding: 10 }}>
                <Box sx={{ width: 80 }}>
                  <Card variant="outlined">
                    <CardContent
                      style={{
                        height: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "green",
                      }}
                    >
                      <text style={{ color: "white" }}>
                        {item.qty ? item.qty : 0}
                      </text>
                    </CardContent>
                    <Divider />
                    <CardActions
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <PinDropIcon style={{ fontSize: "16" }} />
                      <text style={{ fontSize: 12, fontWeight: "bold" }}>
                        {item.location_name}
                      </text>
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            );
          })
        : null}
    </Grid>
  );
}

import axios from "axios";
import {
  TRACKING_LOCATION_REQUEST,
  TRACKING_LOCATION_SUCCESS,
  TRACKING_LOCATION_FAIL,
  TRACKING_LOCATION_CREATE_REQUEST,
  TRACKING_LOCATION_CREATE_SUCCESS,
  TRACKING_LOCATION_CREATE_FAIL,
  TRACKING_LOCATION_CREATE_RESET,
  TRACKING_LOCATION_UPDATE_REQUEST,
  TRACKING_LOCATION_UPDATE_SUCCESS,
  TRACKING_LOCATION_UPDATE_FAIL,
  TRACKING_LOCATION_UPDATE_RESET,
  TRACKING_LOCATION_DELETE_REQUEST,
  TRACKING_LOCATION_DELETE_SUCCESS,
  TRACKING_LOCATION_DELETE_FAIL,
} from "../constants/trackingLocationConstant";
import API_URL from "../../utils/api";

export const trackingLocationList = () => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_LOCATION_REQUEST,
    });

    const { data } = await axios.get(`${API_URL}/api/tracking-locations`);

    dispatch({
      type: TRACKING_LOCATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const trackingLocationCreateAction =
  (location_name, location_description, item_type, psl, photo) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_LOCATION_CREATE_REQUEST,
      });

      const formData = new FormData();
      formData.append("location_name", location_name);
      formData.append("location_description", location_description);
      formData.append("item_type", item_type);
      formData.append("psl", psl);
      formData.append("photo", photo);
      console.log("formData", formData);

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(`${API_URL}/api/tracking-locations`, formData, config)
        .then((res) => {
          console.log(res.data);
        });

      console.log("testDAta", data);

      dispatch({
        type: TRACKING_LOCATION_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_LOCATION_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingLocationUpdateAction =
  (
    id,
    location_name,
    location_description,
    item_type,
    psl,
    photo,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_LOCATION_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `${API_URL}/api/tracking-locations/${id}`,
          {
            location_name,
            location_description,
            item_type,
            psl,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      console.log(
        "update data",
        location_name,
        location_description,
        photo,
        created_by,
        modified_by
      );

      dispatch({
        type: TRACKING_LOCATION_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_LOCATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingLocationDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_LOCATION_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(`${API_URL}/api/tracking-locations/${id}`, config);

    dispatch({
      type: TRACKING_LOCATION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_LOCATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

import React from "react";
import { withStyles } from "@mui/styles";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
// import { userCreateAction, userList } from "../actions/userActions";
import { CreateValidation } from "./FormValidation";
import {
  requisitionList,
  requisitionCreateAction,
} from "../../../../redux/actions/requisitionAction";
import API_URL from "../../../../utils/api";

// import { userRoleList } from "../../../redux/actions/userRoleActions";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>shopping_bag</Icon>
      <Typography
        variant="h5"
        style={{
          marginLeft: 10,
          fontSize: 16,
          fontWeight: "500",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    fabrics,
    measuringUnits,
    reportData,
    reqData,
    qrList,
    stockCount,
  } = props;
  const [uom_qty, setUomqty] = React.useState("Roll");
  const [uom_tracking, setUomtracking] = React.useState("Roll");
  const [status, setStatus] = React.useState("Y");
  const [req_yds_qty, setReqYdsYty] = React.useState("");
  const [getCategory, setCategory] = React.useState({
    _id: "",
    category: "",
    error: false,
  });

  console.log("getCategory", getCategory);

  const [u_id, setU_id] = React.useState(1);

  const dispatch = useDispatch();
  const requisitionCreate = useSelector((state) => state.requisitionCreate);
  const { requisitionsCreate } = requisitionCreate;

  console.log("requisitionsCreate", requisitionsCreate);

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const [job_date, set_job_date] = React.useState(materialDateInput);

  React.useEffect(() => {
    axios
      .get(`${API_URL}/api/tracking_jobs/max-job-id`)
      .then((res) => {
        console.log("response ----:", res);
        setU_id(res.data);
      })
      .catch((err) => {
        console.log("Error ---:", err);
      });
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("req_roll_qty" in fieldValues) {
      temp.req_roll_qty =
        fieldValues.req_roll_qty && fieldValues.req_roll_qty <= stockCount
          ? ""
          : "Invalid Entry Or Check Stock Qty.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate, getCategory, reportData);

  const job_id = reqData.job_id ? reqData.job_id[0] : "";
  const pi_no = reqData.pi_no ? reqData.pi_no[0] : "";
  const buyer_name = reqData.buyer_name ? reqData.buyer_name[0] : "";
  const style = reqData.style ? reqData.style[0] : "";
  const po = reqData.po ? reqData.po[0] : "";
  const composition = reqData.composition ? reqData.composition[0] : "";
  const construction = reqData.construction ? reqData.construction[0] : "";
  const type = reqData.type ? reqData.type[0] : "";
  const category = reqData.category ? reqData.category[0] : "";
  const color = reqData.color ? reqData.color[0] : "";
  const width = reqData.width ? reqData.width[0] : "";
  const gsm = reqData.gsm ? reqData.gsm[0] : "";
  const others = reqData.others ? reqData.others[0] : "";
  const shrinkage_length = reqData.shrinkage_length
    ? reqData.shrinkage_length[0]
    : "";
  const shrinkage_width = reqData.shrinkage_width
    ? reqData.shrinkage_width[0]
    : "";
  const pattern = reqData.pattern ? reqData.pattern[0] : "";
  const shade = reqData.shade ? reqData.shade[0] : "";
  const shipment_date = reqData.shipment_date ? reqData.shipment_date[0] : "";

  React.useEffect(() => {
    if (values.req_roll_qty !== "") {
      const xyz =
        values.req_roll_qty *
        parseInt(reportData.length ? reportData[0].conv_rate : "0");
      setReqYdsYty(xyz);
    }
  }, [values.req_roll_qty]);

  console.log(
    "ppdta",
    JSON.stringify({
      req_roll_qty: values.req_roll_qty,
      req_yds_qty: req_yds_qty,
      job_id: job_id,
      pi_no: pi_no,
      buyer_name: buyer_name,
      style: style,
      po: po,
      composition: composition,
      construction: construction,
      type: type,
      category: category,
      color: color,
      width: width,
      gsm: gsm,
      others: others,
      shrinkage_length: shrinkage_length,
      shrinkage_width: shrinkage_width,
      pattern: pattern,
      shade: shade,
      shipment_date: shipment_date,
    })
  );

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(
        requisitionCreateAction(
          values.req_roll_qty,
          req_yds_qty,
          job_id,
          pi_no,
          buyer_name,
          style,
          po,
          composition,
          construction,
          type,
          category,
          color,
          width,
          gsm,
          others,
          shrinkage_length,
          shrinkage_width,
          pattern,
          shade,
          shipment_date
        )
      );

      setOpenPopup(false);
      setTimeout(() => {
        dispatch(requisitionList());

        setCategory({
          _id: "",
          category: "",
        });
      }, 2000);

      resetForm();
      setReqYdsYty("");
    }
  };

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();

    setCategory({
      _id: "",
      category: "",
    });
  };

  React.useEffect(() => {
    if (requisitionsCreate.length !== 0 && qrList.length !== 0) {
      const postData = qrList
        .slice(0, parseInt(requisitionsCreate.req_roll_qty))
        .map((v) => ({
          ...v,
          requisition_id: requisitionsCreate.id,
        }));

      console.log("postData222", JSON.stringify(postData));

      fetch(`${API_URL}/api/requisition-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(postData),
      }).then((response) => {
        response.json().then((result) => {
          console.log("QR List", result.response);
        });
      });
    }
  }, [requisitionsCreate, qrList]);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          req_yds_qty={req_yds_qty}
          setUomqty={setUomqty}
          setUomtracking={setUomtracking}
          fabrics={fabrics}
          measuringUnits={measuringUnits}
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          u_id={u_id}
          uom_qty={uom_qty}
          uom_tracking={uom_tracking}
          set_job_date={set_job_date}
          job_date={job_date}
          status={status}
          setStatus={setStatus}
          getCategory={getCategory}
          setCategory={setCategory}
          CreateValidation={CreateValidation}
          validate={validate}
          values={values}
          setValues={setValues}
          shipment_date={shipment_date}
          reportData={reportData}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import { Box, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useStyles from "./styles";

// components
import Header from "../header";
import Dashboard from "../pages/Dashboard";
import FabricReports from "../modules/workspace/reports/fabricReports";
import CartonReports from "../modules/workspace/reports/cartonReports";
import Users from "../modules/settings/users";
import Fabrics from "../modules/workspace/fabric";
import FabricsAddInfo from "../modules/workspace/fabAddInfo";
import Unit from "../modules/workspace/measuringUnit";
import Purpose from "../modules/workspace/trackingPurpose";
import Location from "../modules/workspace/trackingLocation";
import Cartons from "../modules/workspace/cartoon";
import PdfViewer from "../utils/pdfViewer";
import FabricReportFile from "../utils/pdfViewer/fabricReport";
import FabricReportsFile from "../utils/pdfViewer/fabric_reports";
import FabricQRCode from "../modules/workspace/trackingCodes";
import CartonQRCode from "../modules/workspace/trackingCodesCartons";
import useMediaQuery from "@mui/material/useMediaQuery";
import API_URL from "../utils/api";
import AppDownload from "../pages/AppDownload";
import FabStock from "../modules/workspace/reports/fabStock";
import FabricReq from "../modules/workspace/reports/requisition";
import FabricRequisition from "../utils/pdfViewer/requisition";

function Layout(props) {
  var classes = useStyles();
  var theme = useTheme();

  var [isPermanent, setPermanent] = useState(true);
  const [user, setUser] = useState("");

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    const session = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : null;

    const token = "Bearer " + session.access_token;

    fetch(`${API_URL}/api/auth/me`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
      },
    }).then((response) => {
      response.json().then((userData) => {
        console.log("Checking user Data", userData);
        setUser(userData);
      });
    });
  }, []);

  const mobileMatches = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const token = sessionStorage.getItem("userInfo");

  if (!token) {
    <Redirect to="/login" />;
  }

  return (
    <div className={classes.root}>
      <Router>
        <Header history={props.history} userData={user} />
        <div
          style={{
            width: "100%",
            height: matches
              ? "calc(100vh - 150px)"
              : mobileMatches
              ? "calc(100vh - 220px)"
              : "calc(100vh - 250px)",
          }}
        >
          <div
            style={{
              paddingLeft: isPermanent ? 50 : 10,
              paddingRight: isPermanent ? 50 : 10,
            }}
          >
            <Switch>
              <Route path="/app/dashboard">
                <Dashboard userData={user} />
              </Route>
              <Route path="/app/app_download">
                <AppDownload userData={user} />
              </Route>
              <Route path="/app/fabrics_qr_code">
                <FabricQRCode userData={user} />
              </Route>
              <Route path="/app/cartons_qr_code">
                <CartonQRCode userData={user} />
              </Route>

              <Route path="/app/fabric_reports">
                <FabricReports userData={user} />
              </Route>
              <Route path="/app/carton_reports">
                <CartonReports userData={user} />
              </Route>
              <Route path="/app/users">
                <Users userData={user} />
              </Route>
              <Route path="/app/fabrics_new_consignment">
                <Fabrics userData={user} />
              </Route>
              <Route path="/app/additional_fab_info">
                <FabricsAddInfo userData={user} />
              </Route>

              <Route path="/app/uom">
                <Unit userData={user} />
              </Route>
              <Route path="/app/purposes">
                <Purpose userData={user} />
              </Route>
              <Route path="/app/bin_numbers">
                <Location userData={user} />
              </Route>
              <Route path="/app/cartons_new_consignment">
                <Cartons userData={user} />
              </Route>
              <Route path="/app/pdf-viewer">
                <PdfViewer appUser={user} />
              </Route>
              <Route path="/app/doc/fabric_stock_reports">
                <FabricReportFile appUser={user} />
              </Route>
              <Route path="/app/doc/fabric_reports">
                <FabricReportsFile appUser={user} />
              </Route>
              <Route path="/app/doc/fabric_requisition">
                <FabricRequisition appUser={user} />
              </Route>
              <Route path="/app/fab_requisition">
                <FabricReq appUser={user} />
              </Route>
              <Route path="/app/fab_stock_status">
                <FabStock />
              </Route>
              <Redirect exact from="/app" to="/app/dashboard" />
            </Switch>
          </div>

          <Box
            mt={5}
            mb={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="http://clonestudiobd.com/">
                Clone Studio
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
        </div>
      </Router>
    </div>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Layout);

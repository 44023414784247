import React from "react";
import QRLogo from "../../assets/images/lg-logo-qr.jpeg";

var QRCode = require("qrcode.react");

// const Printable =(props)=>{
// const {pdfData} = props;

//     return(
// <>
// {console.log("DATA CHECK : ",pdfData)}
//       {pdfData.length ? (

//         <div style={{backgroundColor:'lightgreen'}}>{ pdfData.map(item=>{
//           return <div><img src={item.tracking_code_path} style={{margin:'1em'}}/></div>
//         })}
//        </div>
//       ) : null}
//       {/* <button onClick={()=>window.print()} class="noPrint">Print</button> */}
//     </>
//     );
// }

// export default Printable;

class Printable extends React.Component {
  render() {
    let i = 0;
    let j = 0;
    let im1;
    let im2;

    return (
      <div style={{ textAlign: "center" }}>
        <>
          {" "}
          {console.log("DATA CHECK : ", this.props.pdfData)}
          {this.props.pdfData.length ? (
            <>
              {this.props.pdfData.map((item) => {
                if (i === 0) {
                  console.log("value of i == ----", i);
                  im1 = (
                    <td
                      style={{
                        textAlign: "center",
                        height: "260px",
                        width: "500px",
                      }}
                    >
                      {/* <img
                        src={item.tracking_code_path}
                        height="144px"
                        width="144px"
                      /> */}
                      <QRCode
                        value={item.tracking_code}
                        size={144}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        renderAs={"svg"}
                        imageSettings={{
                          src: `${QRLogo}`,
                          x: null,
                          y: null,
                          height: 24,
                          width: 24,
                          opacity: 0.2,
                        }}
                      />
                      <br />
                      {item.tracking_code}
                    </td>
                  );
                  i++;
                  j++;
                } else if (i === 1) {
                  console.log("value of i == ----", i);
                  im2 = (
                    <td
                      style={{
                        textAlign: "center",
                        height: "260px",
                        width: "500px",
                      }}
                    >
                      {/* <img
                        src={item.tracking_code_path}
                        height="144px"
                        width="144px"
                      /> */}
                      <QRCode
                        value={item.tracking_code}
                        size={144}
                        bgColor={"#ffffff"}
                        fgColor={"#000000"}
                        level={"L"}
                        includeMargin={false}
                        renderAs={"svg"}
                        imageSettings={{
                          src: `${QRLogo}`,
                          x: null,
                          y: null,
                          height: 24,
                          width: 24,
                          opacity: 0.2,
                        }}
                      />
                      <br />
                      {item.tracking_code}
                    </td>
                  );
                  i++;
                  j++;
                }
                if (i === 2) {
                  i = 0;
                  if (j % 6 === 0 || this.props.pdfData.length === j) {
                    // console.log("value of J ====---:",j);
                    return (
                      <table
                        className="prottoy"
                        style={{ textAlign: "center" }}
                      >
                        <tr>
                          {im1}
                          {im2}
                        </tr>
                      </table>
                    );
                  } else
                    return (
                      <table
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <tr>
                          {im1}
                          {im2}
                        </tr>
                      </table>
                    );
                }
              })}
            </>
          ) : null}
          {/* <button onClick={()=>window.print()} class="noPrint">Print</button> */}
        </>
      </div>
    );
  }
}

export default Printable;

import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const { item, value, handleInputChange, error, helperText } = props;

  console.log("edit data", item);

  // const handleChange = (event) => {
  //   setUserStatus(event.target.value);
  // };

  // const handleLogoUpload = (event) => {
  //   const files = event.target.files;
  //   setLogo(files[0]);
  //   console.log("file",files);
  // };
  // const handleSignPhotoUpload = (event) => {
  //   const files = event.target.files;
  //   setSignPhoto(files[0]);
  // };

  // var roleName = item
  //   ? roles
  //       .filter((o1) => {
  //         return o1.id === item.user_role_id;
  //       })
  //       .map((item) => item.role_name)
  //   : null;

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className={classes.textField}
              name="uom"
              variant="outlined"
              required
              fullWidth
              id="uom"
              label="UOM"
              autoFocus
              size="small"
              defaultValue={item ? item.uom : null}
              error={error.uom}
              helperText={helperText.uom}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="description"
              variant="outlined"
              fullWidth
              id="description"
              label="Description"
              autoFocus
              size="small"
              multiline
              rows={3}
              className={classes.textField}
              defaultValue={item ? item.description : null}
              // value={value.alias}
              error={error.description}
              helperText={helperText.description}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

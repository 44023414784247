import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    shrinkage_length: "",
    shrinkage_width: "",
    pattern: "",
    shade: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues({
      shrinkage_length: "",
      shrinkage_width: "",
      pattern: "",
      shade: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    shrinkage_length: "",
    shrinkage_width: "",
    pattern: "",
    shade: "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      shrinkage_length: item.shrinkage_length,
      shrinkage_width: item.shrinkage_width,
      pattern: item.pattern,
      shade: item.shade,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

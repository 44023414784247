import {
  CARTOON_REQUEST,
  CARTOON_SUCCESS,
  CARTOON_FAIL,
  CARTOON_CREATE_REQUEST,
  CARTOON_CREATE_SUCCESS,
  CARTOON_CREATE_FAIL,
  CARTOON_CREATE_RESET,
  CARTOON_UPDATE_REQUEST,
  CARTOON_UPDATE_SUCCESS,
  CARTOON_UPDATE_FAIL,
  CARTOON_UPDATE_RESET,
  CARTOON_DELETE_REQUEST,
  CARTOON_DELETE_SUCCESS,
  CARTOON_DELETE_FAIL,
} from "../constants/cartoonConstant";

export const cartoonReducer = (state = { cartoons: [] }, action) => {
  switch (action.type) {
    case CARTOON_REQUEST:
      return { loading: true, cartoons: [] };
    case CARTOON_SUCCESS:
      return { loading: false, cartoons: action.payload };
    case CARTOON_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cartoonCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CARTOON_CREATE_REQUEST:
      return { loading: true };
    case CARTOON_CREATE_SUCCESS:
      return { loading: false, cartoonCreates: action.payload };
    case CARTOON_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case CARTOON_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const cartoonUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CARTOON_UPDATE_REQUEST:
      return { loading: true };
    case CARTOON_UPDATE_SUCCESS:
      return { loading: false, cartoonUpdates: action.payload };
    case CARTOON_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CARTOON_UPDATE_RESET:
      return { cartoonCreates: {} };
    default:
      return state;
  }
};

export const cartoonDeleteReducer = (state = { cartonsDelete: [] }, action) => {
  switch (action.type) {
    case CARTOON_DELETE_REQUEST:
      return { loading: true, cartonsDelete: [] };
    case CARTOON_DELETE_SUCCESS:
      return { loading: false, success: true, cartonsDelete: action.payload };
    case CARTOON_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React from "react";
import Button from "@mui/material/Button";

const AppDownload = () => {
  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `app-debug.apk`;
    link.href =
      "https://drive.google.com/uc?export=download&id=1NCJd7jK4Q4ONkl0uEUhciYy5-VWJpKkX";
    link.click();
  };

  return (
    <div style={{ width: "100%", minHeight: "65vh" }}>
      <div style={{ marginTop: 20 }}>
        <Button onClick={onDownload} variant="contained" color="primary">
          Download APK
        </Button>
      </div>
    </div>
  );
};

export default AppDownload;

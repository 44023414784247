import {
  REQUISITION_REQUEST,
  REQUISITION_SUCCESS,
  REQUISITION_FAIL,
  REQUISITION_CREATE_REQUEST,
  REQUISITION_CREATE_SUCCESS,
  REQUISITION_CREATE_FAIL,
  REQUISITION_CREATE_RESET,
  REQUISITION_UPDATE_REQUEST,
  REQUISITION_UPDATE_SUCCESS,
  REQUISITION_UPDATE_FAIL,
  REQUISITION_UPDATE_RESET,
  REQUISITION_DELETE_REQUEST,
  REQUISITION_DELETE_SUCCESS,
  REQUISITION_DELETE_FAIL,
} from "../constants/requisitionConstant";

export const requisitionReducer = (state = { requisitions: [] }, action) => {
  switch (action.type) {
    case REQUISITION_REQUEST:
      return { loading: true, requisitions: [] };
    case REQUISITION_SUCCESS:
      return { loading: false, requisitions: action.payload };
    case REQUISITION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requisitionCreateReducer = (
  state = { requisitionsCreate: [] },
  action
) => {
  switch (action.type) {
    case REQUISITION_CREATE_REQUEST:
      return { loading: true, requisitionsCreate: [] };
    case REQUISITION_CREATE_SUCCESS:
      return { loading: false, requisitionsCreate: action.payload };
    case REQUISITION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const requisitionUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case REQUISITION_UPDATE_REQUEST:
      return { loading: true };
    case REQUISITION_UPDATE_SUCCESS:
      return { loading: false, requisitionUpdate: action.payload };
    case REQUISITION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case REQUISITION_UPDATE_RESET:
      return { requisitionUpdate: {} };
    default:
      return state;
  }
};

export const requisitionDeleteReducer = (
  state = { requisitionDelete: [] },
  action
) => {
  switch (action.type) {
    case REQUISITION_DELETE_REQUEST:
      return { loading: true, requisitionDelete: [] };
    case REQUISITION_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        requisitionDelete: action.payload,
      };
    case REQUISITION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import axios from "axios";
import {
  CARTOON_REQUEST,
  CARTOON_SUCCESS,
  CARTOON_FAIL,
  CARTOON_CREATE_REQUEST,
  CARTOON_CREATE_SUCCESS,
  CARTOON_CREATE_FAIL,
  CARTOON_CREATE_RESET,
  CARTOON_UPDATE_REQUEST,
  CARTOON_UPDATE_SUCCESS,
  CARTOON_UPDATE_FAIL,
  CARTOON_UPDATE_RESET,
  CARTOON_DELETE_REQUEST,
  CARTOON_DELETE_SUCCESS,
  CARTOON_DELETE_FAIL,
} from "../constants/cartoonConstant";
import API_URL from "../../utils/api";

export const cartoonList = () => async (dispatch) => {
  try {
    dispatch({
      type: CARTOON_REQUEST,
    });

    const { data } = await axios.get(`${API_URL}/api/tracking-jobs`);

    dispatch({
      type: CARTOON_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CARTOON_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const cartoonCreateAction =
  (
    order_item_id,
    job_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CARTOON_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/tracking-jobs`,
        {
          order_item_id,
          job_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "cartoonDAta",
        order_item_id,
        job_id,
        job_date,
        tracking_qty,
        buyer_name,
        style,
        po,
        type,
        category,
        composition,
        construction,
        width,
        color,
        gsm,
        uom_tracking,
        order_qty,
        uom_qty,
        item_type,
        conversion_rate,
        others,
        remarks,
        created_by,
        modified_by
      );

      dispatch({
        type: CARTOON_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CARTOON_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const cartoonUpdateAction =
  (
    id,
    order_item_id,
    job_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    created_by,
    modified_by
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: CARTOON_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/tracking-jobs/${id}`,
        {
          order_item_id,
          job_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks,
          created_by,
          modified_by,
        },
        config
      );

      console.log(
        "update data",
        order_item_id,
        job_id,
        job_date,
        tracking_qty,
        buyer_name,
        style,
        po,
        type,
        category,
        composition,
        construction,
        width,
        color,
        gsm,
        uom_tracking,
        order_qty,
        uom_qty,
        item_type,
        conversion_rate,
        others,
        remarks,
        created_by,
        modified_by
      );

      dispatch({
        type: CARTOON_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CARTOON_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const cartoonDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: CARTOON_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    const { data } = await axios.delete(
      `${API_URL}/api/tracking-jobs/${id}`,
      config
    );

    console.log("cartonsss", data);

    dispatch({
      type: CARTOON_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CARTOON_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

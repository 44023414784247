import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";

import { useLocation } from "react-router-dom";

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// // Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page wrap>
//       <Text render={({ pageNumber, totalPages }) => (
//         `${pageNumber} / ${totalPages}`
//       )} fixed />

//       <View render={({ pageNumber }) => (
//         pageNumber % 2 === 0 && (
//           <View style={{ background: 'red' }}>
//             <Text>I'm only visible in odd pages!</Text>
//           </View>
//         )
//       )} />
//     </Page>
//   </Document>
// );
const styles = StyleSheet.create({
  title: { width: "100%", marginBottom: 5, borderBottom: "1px solid black" },
  page: { padding: 40, paddingTop: 20 },
  box: { width: "100%", marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const MyDocument = (props) => {
  const { data } = props;
  var d = new Date().toLocaleDateString();

  console.log("pdfData", data);

  return (
    <Document>
      {data.length
        ? data.map((item) => {
            let i = 0;
            let sum = 0;

            return (
              <Page style={styles.page} orientation="potrait" size="A4" wrap>
                <View
                  style={[
                    styles.title,
                    {
                      height: 40,
                      backgroundColor: "white",
                      textAlign: "center",
                    },
                  ]}
                >
                  <Text>Laila Styles Ltd.</Text>
                  <Text style={{ fontSize: "10" }}>
                    Bahadurpur, Shikderbari, Gazipur.
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                    backgroundColor: "white",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "10" }}>Fabric Stock Report</Text>
                </View>
                <View
                  style={{
                    fontSize: "10",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid gray",
                    borderTop: "1 solid black",
                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Job ID
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].job_id ? item[0].job_id.toString() : null}
                  </Text>
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Buyer Name
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].buyer_name ? item[0].buyer_name.toString() : null}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid gray",
                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Construction
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {" "}
                    {item[0].construction
                      ? item[0].construction.toString()
                      : null}
                  </Text>
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Composition
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",

                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {" "}
                    {item[0].composition
                      ? item[0].composition.toString()
                      : null}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid gray",
                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Type
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].type ? item[0].type.toString() : null}
                  </Text>
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Category
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].category ? item[0].category.toString() : null}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid gray",
                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Style
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].style ? item[0].style.toString() : null}
                  </Text>
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Color
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].color ? item[0].color.toString() : null}
                  </Text>
                </View>

                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid black",

                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    Width
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].width ? item[0].width.toString() : null}
                  </Text>
                  <Text
                    style={{
                      flex: "20",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid gray",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    GSM
                  </Text>
                  <Text
                    style={{
                      flex: "30",
                      paddingTop: "5",
                      paddingBottom: "5",
                      paddingLeft: 2,
                      paddingRight: 2,
                    }}
                  >
                    {item[0].gsm ? item[0].gsm.toString() : null}
                  </Text>
                </View>
                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    marginTop: "10",
                    border: "1 solid black",
                    //  backgroundColor:'black',
                    color: "black",
                  }}
                >
                  <Text
                    style={{
                      flex: "5",
                      paddingTop: "10",
                      paddingBottom: "10",
                      borderRight: "1 solid black",
                    }}
                  >
                    Sl
                  </Text>
                  <Text
                    style={{
                      flex: "45",
                      paddingTop: "10",
                      paddingBottom: "10",
                      borderRight: "1 solid black",
                    }}
                  >
                    Location Name
                  </Text>
                  <Text
                    style={{
                      flex: "25",
                      paddingTop: "10",
                      paddingBottom: "10",
                      borderRight: "1 solid black",
                      paddingRight: "5",
                    }}
                  >
                    Roll Quantity
                  </Text>
                  <Text
                    style={{
                      flex: "15",
                      paddingTop: "10",
                      paddingBottom: "10",
                      borderRight: "1 solid black",
                    }}
                  >
                    Remarks
                  </Text>
                </View>

                {item
                  ? item.map((items) => {
                      sum = sum + parseInt(items.qty);
                      return (
                        <View
                          style={{
                            fontSize: "11",
                            display: "flex",
                            flexWrap: "wrap",
                            textAlign: "center",
                            flexDirection: "row",
                            textAlign: "left",
                            borderBottom: "1 solid black",
                            borderLeft: "1 solid black",
                            borderRight: "1 solid black",
                          }}
                        >
                          <Text
                            style={{
                              flex: "5",
                              paddingTop: "5",
                              paddingBottom: "5",
                              borderRight: "1 solid black",
                              textAlign: "center",
                            }}
                          >
                            {++i}
                          </Text>
                          <Text
                            style={{
                              flex: "45",
                              paddingTop: "5",
                              paddingBottom: "5",
                              borderRight: "1 solid black",

                              textAlign: "center",
                            }}
                          >
                            {items.location_name}
                          </Text>
                          <Text
                            style={{
                              flex: "25",
                              paddingTop: "5",
                              textAlign: "right",
                              paddingBottom: "5",
                              paddingRight: "5",
                              borderRight: "1 solid black",
                            }}
                          >
                            {items.qty}
                          </Text>
                          <Text
                            style={{
                              flex: "15",
                              paddingTop: "5",
                              paddingBottom: "5",
                              borderRight: "1 solid black",
                            }}
                          ></Text>
                        </View>
                      );
                    })
                  : null}

                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid black",
                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "5",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid black",
                    }}
                  ></Text>
                  <Text
                    style={{
                      flex: "45",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid black",

                      textAlign: "center",
                    }}
                  >
                    Total
                  </Text>
                  <Text
                    style={{
                      flex: "25",
                      paddingTop: "5",
                      paddingBottom: "5",
                      paddingRight: "5",
                      borderRight: "1 solid black",
                      textAlign: "right",
                    }}
                  >
                    {sum}
                  </Text>
                  <Text
                    style={{
                      flex: "15",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid black",
                    }}
                  ></Text>
                </View>

                <Text
                  style={{
                    position: "absolute",
                    fontSize: 12,
                    bottom: 20,
                    left: 0,
                    right: 20,
                    textAlign: "right",
                    color: "grey",
                  }}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              </Page>
            );
          })
        : null}
      {console.log("pdfData222", data)}
    </Document>
  );
};

// export default ReactPDF.render(doc);
export default MyDocument;

import React, { useState, useEffect, useRef } from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import { makeStyles, withStyles } from "@mui/styles";

const SearchBox = styled(TextField)(() => ({
  "& fieldset": {},
}));

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  leftColumn: {
    borderBottom: "1px solid grey",
    borderLeft: "1px solid grey",
    padding: 7,
    backgroundColor: "#EAEAEA",
  },
  leftColumnRemarks: {
    borderBottom: "1px solid grey",
    borderLeft: "1px solid grey",
    paddingLeft: 7,
    paddingTop: 18,
    paddingBottom: 18,
    paddingRight: 7,
    backgroundColor: "#EAEAEA",
  },
  rightColumn: {},

  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .Mui-error": {
      color: "#FF1010",
      borderWidth: 2,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "grey",
        borderRadius: `0px 0 0 0px`,
      },
      "&:hover fieldset": {
        borderColor: "black",
        borderWidth: 1,
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

const InputForm = (props) => {
  const classes = useStyles();
  const scrollRef = useRef(null);

  const {
    value,
    handleInputChange,
    error,
    helperText,
    setUomqty,
    setUomtracking,
    setCurrency,
    measuringUnits,
    item,
    u_id,
    uom_qty,
    uom_tracking,
    set_job_date,
    shipment_date,
    set_shipment_date,
    job_date,
    fabrics,
    setStatus,
    status,
    getCategory,
    setCategory,
    CreateValidation,
    validate,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  console.log("edit data", item);

  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    return newArray;
  }

  const categoryList = removeDuplicates(fabrics, "category");

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, [scrollRef.current]);

  return (
    <div className={classes.root} ref={scrollRef}>
      <form className={classes.form} noValidate>
        <Grid container>
          <Grid
            container
            xs={6}
            sm={6}
            style={{ width: "100%", borderTop: "1px solid grey" }}
          >
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Job ID</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>PI No.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Buyer</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Style</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>PO No.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Shipment Date
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Fab. Category
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Fab. Type</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Width</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Construction
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Composition
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Color</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>GSM</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Others</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Order Qty (Roll)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Order Qty (Yds)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>Lot No.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Receive Date
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Lot Qty (Roll)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumn}>
              <Typography style={{ fontWeight: "bold" }}>
                Lot Qty (Yds)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.leftColumnRemarks}>
              <Typography style={{ fontWeight: "bold" }}>Remarks</Typography>
            </Grid>
          </Grid>
          <Grid container xs={6} sm={6} style={{ width: "100%" }}>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="job_id"
                id="job_id"
                autoFocus
                fullWidth
                size="small"
                className={classes.textField}
                defaultValue={item ? item.job_id : u_id}
                error={error.job_id}
                onChange={handleInputChange}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="pi_no"
                id="pi_no"
                autoFocus
                fullWidth
                size="small"
                className={classes.textField}
                defaultValue={item ? item.pi_no : null}
                error={error.pi_no}
                onChange={handleInputChange}
                placeholder="Enter PI No.*"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="buyer_name"
                required
                fullWidth
                id="buyer_name"
                placeholder="Enter Buyer Name*"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.buyer_name : null}
                error={error.buyer_name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="style"
                required
                fullWidth
                id="style"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.style : null}
                error={error.style}
                onChange={handleInputChange}
                placeholder="Enter Style*"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="po"
                required
                fullWidth
                id="po"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.po : null}
                error={error.po}
                onChange={handleInputChange}
                placeholder="Enter PO No.*"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                id="shipment_date"
                size="small"
                fullWidth
                required
                name="shipment_date"
                type="date"
                autoFocus
                defaultValue={item ? item.shipment_date : shipment_date}
                className={classes.textField}
                helperText={helperText.shipment_date}
                onChange={(e) => set_shipment_date(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <FormControl
                variant="outlined"
                fullWidth
                sx={{ display: "flex" }}
              >
                <Autocomplete
                  fullWidth
                  id="size-small-outlined-multi"
                  size="small"
                  value={getCategory}
                  options={categoryList}
                  onChange={(e, v) => {
                    if (v)
                      setCategory({
                        _id: v.id,
                        category: v.category,
                        error: false,
                      });
                    else setCategory({ _id: "", category: "", error: false });
                  }}
                  getOptionSelected={(option, value) =>
                    option.category === value.category
                  }
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    console.log("inputValue", inputValue);

                    const isExisting = options.some(
                      (option) => inputValue === option.category
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        inputValue,
                        category: inputValue,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.category;
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      name="category"
                      required
                      fullWidth
                      id="category"
                      autoFocus
                      className={classes.textField}
                      error={getCategory.error ? true : false}
                      placeholder="Select A Category*"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="type"
                fullWidth
                id="type"
                required
                autoFocus
                size="small"
                className={classes.textField}
                error={error.type}
                defaultValue={item ? item.type : null}
                onChange={handleInputChange}
                placeholder="Enter Fab. Type"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="width"
                required
                fullWidth
                id="width"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.width : null}
                error={error.width}
                onChange={handleInputChange}
                placeholder="Enter Width"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="construction"
                fullWidth
                id="construction"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.construction : null}
                error={error.construction}
                onChange={handleInputChange}
                placeholder="Enter Construction"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="composition"
                fullWidth
                id="composition"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.composition : null}
                error={error.composition}
                onChange={handleInputChange}
                placeholder="Enter Composition"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="color"
                fullWidth
                required
                id="color"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.color : null}
                onChange={handleInputChange}
                error={error.color}
                placeholder="Enter Color"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="gsm"
                fullWidth
                id="gsm"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.gsm : null}
                error={error.gsm}
                onChange={handleInputChange}
                placeholder="Enter GSM"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="others"
                fullWidth
                id="others"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.others : null}
                error={error.others}
                onChange={handleInputChange}
                placeholder="Enter Others Info"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="order_qty_roll"
                fullWidth
                id="order_qty_roll"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.order_qty : null}
                error={error.order_qty_roll}
                onChange={handleInputChange}
                placeholder="Enter Order Qty (Roll)"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="order_qty_yds"
                fullWidth
                id="order_qty_yds"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={
                  item ? item.order_qty * item.conversion_rate : null
                }
                error={error.order_qty_yds}
                onChange={handleInputChange}
                placeholder="Enter Order Qty (Yds)"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="lot_no"
                fullWidth
                id="lot_no"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.lot_no : null}
                error={error.lot_no}
                onChange={handleInputChange}
                placeholder="Enter Lot No."
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                id="job_date"
                size="small"
                fullWidth
                required
                name="job_date"
                type="date"
                autoFocus
                defaultValue={item ? item.job_date : job_date}
                className={classes.textField}
                helperText={helperText.job_date}
                onChange={(e) => set_job_date(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="lot_qty_roll"
                fullWidth
                id="lot_qty_roll"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.tracking_qty : null}
                error={error.lot_qty_roll}
                onChange={handleInputChange}
                placeholder="Lot Qty (Roll)"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="lot_qty_yds"
                fullWidth
                id="lot_qty_yds"
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={
                  item ? item.tracking_qty * item.lot_conversion_rate : null
                }
                error={error.lot_qty_yds}
                onChange={handleInputChange}
                placeholder="Lot Qty (Yds)"
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.rightColumn}>
              <CssTextField
                name="remarks"
                fullWidth
                id="remarks"
                multiline
                rows={2}
                autoFocus
                size="small"
                className={classes.textField}
                defaultValue={item ? item.remarks : null}
                error={error.remarks}
                onChange={handleInputChange}
                placeholder="Enter Remarks"
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

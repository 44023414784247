export const TRACKING_JOBS_LIST_REQUEST = "TRACKING_JOBS_LIST_REQUEST";
export const TRACKING_JOBS_LIST_SUCCESS = "TRACKING_JOBS_LIST_SUCCESS";
export const TRACKING_JOBS_LIST_FAIL = "TRACKING_JOBS_LIST_FAIL";

export const TRACKING_JOBS_CREATE_REQUEST = "TRACKING_JOBS_CREATE_REQUEST";
export const TRACKING_JOBS_CREATE_SUCCESS = "TRACKING_JOBS_CREATE_SUCCESS";
export const TRACKING_JOBS_CREATE_FAIL = "TRACKING_JOBS_CREATE_FAIL";

export const TRACKING_JOBS_UPDATE_REQUEST = "TRACKING_JOBS_UPDATE_REQUEST";
export const TRACKING_JOBS_UPDATE_SUCCESS = "TRACKING_JOBS_UPDATE_SUCCESS";
export const TRACKING_JOBS_UPDATE_FAIL = "TRACKING_JOBS_UPDATE_FAIL";

export const TRACKING_JOBS_DELETE_REQUEST = "TRACKING_JOBS_DELETE_REQUEST";
export const TRACKING_JOBS_DELETE_SUCCESS = "TRACKING_JOBS_DELETE_SUCCESS";
export const TRACKING_JOBS_DELETE_FAIL = "TRACKING_JOBS_DELETE_FAIL";

export const TRACKING_JOBS_PERMISSION_REQUEST =
  "TRACKING_JOBS_PERMISSION_REQUEST";
export const TRACKING_JOBS_PERMISSION_SUCCESS =
  "TRACKING_JOBS_PERMISSION_SUCCESS";
export const TRACKING_JOBS_PERMISSION_FAIL = "TRACKING_JOBS_PERMISSION_FAIL";

import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./FabricReport";
import { useLocation } from "react-router-dom";
import API_URL from "../../api";

const Index = () => {
  let location = useLocation();
  const [reqDetails, setReqDetails] = React.useState([]);
  const [resData, setResData] = React.useState([]);

  React.useEffect(() => {
    if (location.state) {
      setResData(location.state);
    }
  }, [location]);

  console.log("data111", resData);

  React.useEffect(() => {
    if (resData.length !== 0) {
      fetch(`${API_URL}/api/requisition-details`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((response) => {
        response.json().then((result) => {
          console.log("QR List", result);

          const filterData = result
            .filter((item) => {
              return item.requisition_id === resData.id.toString();
            })
            .map((item) => item);

          console.log("report-filter", filterData);
          setReqDetails(filterData);
        });
      });
    }
  }, [resData]);

  return (
    <div className="App">
      <PDFViewer width={"100%"} height={800} showToolbar={true}>
        <MyDocument resData={resData} reqDetails={reqDetails} />
      </PDFViewer>
    </div>
  );
};

export default Index;

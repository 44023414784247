import React from "react";
import { withStyles, makeStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { green } from "@mui/material/colors";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PrintIcon from "@mui/icons-material/Print";
import PrintInput from "./printInput";
import { useDispatch, useSelector } from "react-redux";
// import { userCreateAction, userList } from "../actions/userActions";
import { CreateValidation } from "./FormValidation";

import { useHistory } from "react-router-dom";

//redux
import {
  trackingCodesCreateAction,
  trackingCodesList,
} from "../../../redux/actions/trackingCodesAction";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    color: "white",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <PrintIcon style={{ fontSize: 25 }} />
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(1, 0, 1),
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "40%",
    marginTop: -10,
    marginLeft: -12,
  },
}));

const Popup = (props) => {
  const {
    openPrintPopup,
    setOpenPrintPopup,
    notify,
    setNotify,
    orders,
    orderItems,
    trackingCodes,
    trackingJobs,
  } = props;
  const classes = useStyles();
  const [role_status, setRoleStatus] = React.useState("A");
  const [order_item_id, setOrderItemID] = React.useState("");
  const [order_id, setOrderID] = React.useState("");
  const [tracking_job_id, setTrackingJobID] = React.useState("");
  const [pdfData, setPdfData] = React.useState("");
  const [isLoading, setLoading] = React.useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const roleCreate = useSelector((state) => state.roleCreate);
  const { roleCreates } = roleCreate;

  console.log("added", roleCreates);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("role_name" in fieldValues)
      temp.role_name = fieldValues.role_name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const job_date = values.job_date;
  const tracking_qty = values.tracking_qty;
  const remarks = values.remarks;

  console.log("post-Data", tracking_job_id);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch("http://tracking.clonestudiobd.com/api/tracking-codes/printable", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ tracking_job_id: tracking_job_id }),
    }).then((response) => {
      response.json().then((result) => {
        console.log("Print-array", result);
        if (result.length) {
          setLoading(false);
          history.push({
            pathname: `/app/pdf-viewer`,
            state: result,
          });
        }
      });
    });
  };

  const handleClose = () => {
    setOpenPrintPopup(false);
    resetForm();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPrintPopup}
      fullWidth
    >
      <Dialog_Title id="customized-dialog-title" onClose={handleClose}>
        Print
      </Dialog_Title>
      <Dialog_Content dividers>
        <PrintInput
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          setOrderItemID={setOrderItemID}
          orders={orders}
          orderItems={orderItems}
          setOrderID={setOrderID}
          order_id={order_id}
          tracking_job_id={tracking_job_id}
          setTrackingJobID={setTrackingJobID}
          trackingCodes={trackingCodes}
          trackingJobs={trackingJobs}
          order_item_id={order_item_id}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
          className={classes.submit}
        >
          Print
        </Button>
        {isLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";

import { useLocation } from "react-router-dom";

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// // Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page wrap>
//       <Text render={({ pageNumber, totalPages }) => (
//         `${pageNumber} / ${totalPages}`
//       )} fixed />

//       <View render={({ pageNumber }) => (
//         pageNumber % 2 === 0 && (
//           <View style={{ background: 'red' }}>
//             <Text>I'm only visible in odd pages!</Text>
//           </View>
//         )
//       )} />
//     </Page>
//   </Document>
// );
const styles = StyleSheet.create({
  title: { width: "100%", marginBottom: 5, borderBottom: "1px solid black" },
  page: { padding: 40, paddingTop: 20 },
  box: { width: "100%", marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const MyDocument = (props) => {
  const { resData, reqDetails } = props;
  var d = new Date().toLocaleDateString();
  let i = 0;

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy;

  console.log("resData", resData);
  console.log("reqDetails", reqDetails);

  return (
    <Document>
      <Page style={styles.page} orientation="potrait" size="A4" wrap>
        <View
          style={[
            styles.title,
            {
              height: 40,
              backgroundColor: "white",
              textAlign: "center",
            },
          ]}
        >
          <Text>Laila Styles Ltd.</Text>
          <Text style={{ fontSize: "10" }}>
            Bahadurpur, Shikderbari, Gazipur.
          </Text>
        </View>
        <View
          style={{
            marginTop: 5,
            marginBottom: 5,
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          <Text style={{ fontSize: "10" }}>Issue Requisition</Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <View style={{ marginTop: 20, flex: 1 }}>
            <Text
              style={{
                fontSize: "12",
                fontWeight: "bold",
              }}
            >
              Date :
              <Text
                style={{
                  fontSize: "12",
                }}
              >
                {today}
              </Text>
            </Text>
          </View>
          <View style={{ marginTop: 20, flex: 1, textAlign: "right" }}>
            <Text
              style={{
                fontSize: "12",
                fontWeight: "bold",
              }}
            >
              Requisition No. :
              <Text
                style={{
                  fontSize: "12",
                }}
              >
                {resData.id}
              </Text>
            </Text>
          </View>
        </View>

        <View style={{ marginTop: 20 }}>
          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",
              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Job ID
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.job_id ? resData.job_id.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Buyer Name
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.buyer_name ? resData.buyer_name.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              PI No.
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.pi_no ? resData.pi_no.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Style
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.style ? resData.style.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Fab. Category
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.category ? resData.category.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Fab. Type
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.type ? resData.type.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              PO
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.po ? resData.po.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shipment Date
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.shipment_date ? resData.shipment_date.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Width
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.width ? resData.width.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Color
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.color ? resData.color.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              GSM
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.gsm ? resData.gsm.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Others
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.others ? resData.others.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Construction
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.construction ? resData.construction.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Composition
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.composition ? resData.composition.toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shrinkage in Length
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.shrinkage_length
                ? resData.shrinkage_length.toString()
                : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shrinkage in Width
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.shrinkage_width
                ? resData.shrinkage_width.toString()
                : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",
              borderBottom: "1 solid black",
              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Pattern
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.pattern ? resData.pattern.toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shade
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {resData.shade ? resData.shade.toString() : null}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 20,
            minHeight: 70,
          }}
        >
          <View
            style={{
              fontSize: "11",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "column",
              color: "black",
              width: "100%",
            }}
          >
            <View
              style={{
                flex: "100",
                paddingTop: "5",
                paddingBottom: "5",
                borderBottom: "1 solid black",
                borderTop: "1 solid black",
                borderRight: "1 solid black",
                borderLeft: "1 solid black",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D1CFCF",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 12 }}>
                Requisition QTY
              </Text>
            </View>
            <View
              style={{
                flex: "100",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                borderBottom: "1 solid black",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  paddingTop: "5",
                  paddingBottom: "5",
                  borderLeft: "1 solid black",
                  borderRight: "1 solid black",
                }}
              >
                Roll Qty
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderRight: "1 solid black",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                {resData.req_roll_qty ? resData.req_roll_qty.toString() : null}
              </Text>
            </View>
            <View
              style={{
                flex: "100",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                borderBottom: "1 solid black",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  paddingTop: "5",
                  paddingBottom: "5",
                  borderLeft: "1 solid black",
                  borderRight: "1 solid black",
                }}
              >
                Yds
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderRight: "1 solid black",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                {resData.req_yds_qty ? resData.req_yds_qty.toString() : null}
              </Text>
            </View>
          </View>
        </View>

        <View
          style={{
            fontSize: "11",
            display: "flex",
            flexWrap: "wrap",
            textAlign: "center",
            flexDirection: "row",
            marginTop: "10",
            border: "1 solid black",
            backgroundColor: "#D1CFCF",
            color: "black",
            marginTop: 20,
          }}
        >
          <Text
            style={{
              flex: "5",
              paddingTop: "5",
              paddingBottom: "5",
              borderRight: "1 solid black",
            }}
          >
            Sl
          </Text>
          <Text
            style={{
              flex: "40",
              paddingTop: "5",
              paddingBottom: "5",
              borderRight: "1 solid black",
            }}
          >
            Bin Number
          </Text>
          <Text
            style={{
              flex: "40",
              paddingTop: "5",
              paddingBottom: "5",
              borderRight: "1 solid black",
              paddingRight: "5",
            }}
          >
            QR Code
          </Text>
          <Text
            style={{
              flex: "15",
              paddingTop: "5",
              paddingBottom: "5",
            }}
          >
            Remarks
          </Text>
        </View>

        {reqDetails.length
          ? reqDetails.map((items) => {
              return (
                <View
                  style={{
                    fontSize: "11",
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    flexDirection: "row",
                    textAlign: "left",
                    borderBottom: "1 solid black",
                    borderLeft: "1 solid black",
                    borderRight: "1 solid black",
                  }}
                >
                  <Text
                    style={{
                      flex: "5",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid black",
                      textAlign: "center",
                    }}
                  >
                    {++i}
                  </Text>
                  <Text
                    style={{
                      flex: "40",
                      paddingTop: "5",
                      paddingBottom: "5",
                      borderRight: "1 solid black",

                      textAlign: "center",
                    }}
                  >
                    {items.location_name}
                  </Text>
                  <Text
                    style={{
                      flex: "40",
                      paddingTop: "5",
                      textAlign: "right",
                      paddingBottom: "5",
                      paddingRight: "5",
                      borderRight: "1 solid black",
                    }}
                  >
                    {items.tracking_code}
                  </Text>
                  <Text
                    style={{
                      flex: "15",
                      paddingTop: "5",
                      paddingBottom: "5",
                    }}
                  ></Text>
                </View>
              );
            })
          : null}

        <Text
          style={{
            position: "absolute",
            fontSize: 12,
            bottom: 20,
            left: 0,
            right: 20,
            textAlign: "right",
            color: "grey",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

// export default ReactPDF.render(doc);
export default MyDocument;

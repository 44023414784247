import {
  MEASURING_UNIT_REQUEST,
  MEASURING_UNIT_SUCCESS,
  MEASURING_UNIT_FAIL,
  MEASURING_UNIT_CREATE_REQUEST,
  MEASURING_UNIT_CREATE_SUCCESS,
  MEASURING_UNIT_CREATE_FAIL,
  MEASURING_UNIT_CREATE_RESET,
  MEASURING_UNIT_UPDATE_REQUEST,
  MEASURING_UNIT_UPDATE_SUCCESS,
  MEASURING_UNIT_UPDATE_FAIL,
  MEASURING_UNIT_UPDATE_RESET,
  MEASURING_UNIT_DELETE_REQUEST,
  MEASURING_UNIT_DELETE_SUCCESS,
  MEASURING_UNIT_DELETE_FAIL,
} from "../constants/measuringUnitConstant";

export const measuringUnitReducer = (state = { measuringUnits: [] }, action) => {
  switch (action.type) {
    case MEASURING_UNIT_REQUEST:
      return { loading: true, measuringUnits: [] };
    case MEASURING_UNIT_SUCCESS:
      return { loading: false, measuringUnits: action.payload };
    case MEASURING_UNIT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const measuringUnitCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEASURING_UNIT_CREATE_REQUEST:
      return { loading: true };
    case MEASURING_UNIT_CREATE_SUCCESS:
      return { loading: false, measuringUnitCreates: action.payload };
    case MEASURING_UNIT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MEASURING_UNIT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const measuringUnitUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEASURING_UNIT_UPDATE_REQUEST:
      return { loading: true };
    case MEASURING_UNIT_UPDATE_SUCCESS:
      return { loading: false, measuringUnitUpdates: action.payload };
    case MEASURING_UNIT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MEASURING_UNIT_UPDATE_RESET:
      return { measuringUnitCreates: {} };
    default:
      return state;
  }
};

export const measuringUnitDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MEASURING_UNIT_DELETE_REQUEST:
      return { loading: true };
    case MEASURING_UNIT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MEASURING_UNIT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

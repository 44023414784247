import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";

import { useLocation } from "react-router-dom";

// Create styles
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// });

// // Create Document Component
// const MyDocument = () => (
//   <Document>
//     <Page wrap>
//       <Text render={({ pageNumber, totalPages }) => (
//         `${pageNumber} / ${totalPages}`
//       )} fixed />

//       <View render={({ pageNumber }) => (
//         pageNumber % 2 === 0 && (
//           <View style={{ background: 'red' }}>
//             <Text>I'm only visible in odd pages!</Text>
//           </View>
//         )
//       )} />
//     </Page>
//   </Document>
// );
const styles = StyleSheet.create({
  title: { width: "100%", marginBottom: 5, borderBottom: "1px solid black" },
  page: { padding: 40, paddingTop: 20 },
  box: { width: "100%", marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const MyDocument = (props) => {
  const { reqData, resData } = props;
  var d = new Date().toLocaleDateString();

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = dd + "-" + mm + "-" + yyyy;

  console.log("reqData", reqData);
  console.log("resData", resData);

  return (
    <Document>
      <Page style={styles.page} orientation="potrait" size="A4" wrap>
        <View
          style={[
            styles.title,
            {
              height: 40,
              backgroundColor: "white",
              textAlign: "center",
            },
          ]}
        >
          <Text>Laila Styles Ltd.</Text>
          <Text style={{ fontSize: "10" }}>
            Bahadurpur, Shikderbari, Gazipur.
          </Text>
        </View>
        <View
          style={{
            marginTop: 5,
            marginBottom: 5,
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          <Text style={{ fontSize: "10" }}>Fabric Report</Text>
        </View>
        <View style={{ marginTop: 20 }}>
          <Text
            style={{
              fontSize: "12",
              fontWeight: "bold",
            }}
          >
            Date :
            <Text
              style={{
                fontSize: "12",
              }}
            >
              {today}
            </Text>
          </Text>
        </View>
        <View style={{ marginTop: 20 }}>
          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Job ID
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.job_id ? reqData.job_id[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Buyer Name
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.buyer_name ? reqData.buyer_name[0].toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              PI No.
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.pi_no ? reqData.pi_no[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Style
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.style ? reqData.style[0].toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Fab. Category
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.category ? reqData.category[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Fab. Type
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.type ? reqData.type[0].toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              PO
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.po ? reqData.po[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shipment Date
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.shipment_date
                ? reqData.shipment_date[0].toString()
                : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Width
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.width ? reqData.width[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Color
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.color ? reqData.color[0].toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              GSM
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.gsm ? reqData.gsm[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Others
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.others ? reqData.others[0].toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Construction
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.construction ? reqData.construction[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Composition
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.composition ? reqData.composition[0].toString() : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",

              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shrinkage in Length
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.shrinkage_length
                ? reqData.shrinkage_length[0].toString()
                : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shrinkage in Width
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.shrinkage_width
                ? reqData.shrinkage_width[0].toString()
                : null}
            </Text>
          </View>

          <View
            style={{
              fontSize: "10",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "row",
              textAlign: "left",
              borderBottom: "1 solid black",
              borderTop: "1 solid black",
              borderLeft: "1 solid black",
              borderRight: "1 solid black",
            }}
          >
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Pattern
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.pattern ? reqData.pattern[0].toString() : null}
            </Text>
            <Text
              style={{
                flex: "20",
                paddingTop: "5",
                paddingBottom: "5",
                borderRight: "1 solid gray",
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "#D1CFCF",
              }}
            >
              Shade
            </Text>
            <Text
              style={{
                flex: "30",
                paddingTop: "5",
                paddingBottom: "5",
                paddingLeft: 2,
                paddingRight: 2,
              }}
            >
              {reqData.shade ? reqData.shade[0].toString() : null}
            </Text>
          </View>
        </View>

        <View
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 20,
            minHeight: 70,
          }}
        >
          <View
            style={{
              fontSize: "11",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "column",
              color: "black",
              width: "45%",
            }}
          >
            <View
              style={{
                flex: "100",
                paddingTop: "5",
                paddingBottom: "5",
                borderBottom: "1 solid black",
                borderTop: "1 solid black",
                borderRight: "1 solid black",
                borderLeft: "1 solid black",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D1CFCF",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 12 }}>Roll</Text>
            </View>
            <View
              style={{
                flex: "100",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
                borderBottom: "1 solid black",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderRight: "1 solid black",

                  borderTop: "1 solid black",
                  borderLeft: "1 solid black",
                  backgroundColor: "#D1CFCF",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                Receive
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderRight: "1 solid black",
                  borderBottom: "1 solid black",
                  borderTop: "1 solid black",

                  backgroundColor: "#D1CFCF",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                Issue
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderTop: "1 solid black",
                  borderBottom: "1 solid black",
                  borderRight: "1 solid black",
                  backgroundColor: "#D1CFCF",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                Balance
              </Text>
            </View>

            {resData.length
              ? resData.map((item) => {
                  return (
                    <View
                      style={{
                        flex: "100",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        backgroundColor: "white",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          flex: 1,
                          borderRight: "1 solid black",
                          borderBottom: "1 solid black",
                          borderTop: "1 solid black",
                          borderLeft: "1 solid black",

                          paddingTop: "5",
                          paddingBottom: "5",
                        }}
                      >
                        {item.Rec_roll}
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          flex: 1,
                          borderRight: "1 solid black",
                          borderBottom: "1 solid black",
                          borderTop: "1 solid black",
                          paddingTop: "5",
                          paddingBottom: "5",
                        }}
                      >
                        {item.Iss_roll}
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          flex: 1,
                          borderTop: "1 solid black",
                          borderBottom: "1 solid black",
                          borderRight: "1 solid black",
                          paddingTop: "5",
                          paddingBottom: "5",
                        }}
                      >
                        {item.roll_bal}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
          <View style={{ width: "10%" }}></View>
          <View
            style={{
              fontSize: "11",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              flexDirection: "column",
              color: "black",
              width: "45%",
            }}
          >
            <View
              style={{
                flex: "100",
                paddingTop: "5",
                paddingBottom: "5",
                borderBottom: "1 solid black",
                borderTop: "1 solid black",
                borderRight: "1 solid black",
                borderLeft: "1 solid black",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#D1CFCF",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 12 }}>Yds</Text>
            </View>
            <View
              style={{
                flex: "100",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderRight: "1 solid black",
                  borderBottom: "1 solid black",
                  borderTop: "1 solid black",
                  borderLeft: "1 solid black",
                  backgroundColor: "#D1CFCF",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                Receive
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderRight: "1 solid black",
                  borderBottom: "1 solid black",
                  borderTop: "1 solid black",

                  backgroundColor: "#D1CFCF",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                Issue
              </Text>
              <Text
                style={{
                  textAlign: "center",
                  fontSize: 10,
                  flex: 1,
                  borderTop: "1 solid black",
                  borderBottom: "1 solid black",
                  borderRight: "1 solid black",
                  backgroundColor: "#D1CFCF",
                  paddingTop: "5",
                  paddingBottom: "5",
                }}
              >
                Balance
              </Text>
            </View>

            {resData.length
              ? resData.map((item) => {
                  return (
                    <View
                      style={{
                        flex: "100",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        backgroundColor: "white",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          flex: 1,
                          borderRight: "1 solid black",
                          borderBottom: "1 solid black",
                          borderTop: "1 solid black",
                          borderLeft: "1 solid black",

                          paddingTop: "5",
                          paddingBottom: "5",
                        }}
                      >
                        {item.Rec_yrd}
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          flex: 1,
                          borderRight: "1 solid black",
                          borderBottom: "1 solid black",
                          borderTop: "1 solid black",
                          paddingTop: "5",
                          paddingBottom: "5",
                        }}
                      >
                        {item.Iss_yrd}
                      </Text>
                      <Text
                        style={{
                          textAlign: "center",
                          fontSize: 10,
                          flex: 1,
                          borderTop: "1 solid black",
                          borderBottom: "1 solid black",
                          borderRight: "1 solid black",
                          paddingTop: "5",
                          paddingBottom: "5",
                        }}
                      >
                        {item.yrd_bal}
                      </Text>
                    </View>
                  );
                })
              : null}
          </View>
        </View>

        <Text
          style={{
            position: "absolute",
            fontSize: 12,
            bottom: 20,
            left: 0,
            right: 20,
            textAlign: "right",
            color: "grey",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

// export default ReactPDF.render(doc);
export default MyDocument;

import React, { useEffect } from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import InputForm from "./EditForm";
import { EditValidation } from "./FormValidation";

//redux
import { useDispatch, useSelector } from "react-redux";
import {
  trackingCodesUpdateAction,
  trackingCodesListFix,
} from "../../../redux/actions/trackingCodesAction";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
  },
  closeButton: {
    color: theme.palette.grey[500],
    height: 50,
    width: 50,
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>pin</Icon>
      <Typography
        variant="h6"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const { openEditPopup, setOpenEditPopup, item, notify, setNotify } = props;
  const [order_item_id, setOrderNo] = React.useState("");
  const [printable, set_printable] = React.useState("");

  const id = item.id;

  const dispatch = useDispatch();

  useEffect(() => {
    set_printable(item.printable);
  }, [item]);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("role_name" in fieldValues)
      temp.role_name = fieldValues.role_name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    EditValidation(item, true, validate);

  console.log("post-Data", printable);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(trackingCodesUpdateAction(id, printable));
      setOpenEditPopup(false);
      setTimeout(() => {
        dispatch(trackingCodesListFix({ item_type: "Fabric" }));
      }, 2000);
      resetForm();
    }
  };

  const handleClose = () => {
    setOpenEditPopup(false);
    resetForm();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openEditPopup}
      onClose={handleClose}
      fullWidth
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Modify Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          printable={printable}
          set_printable={set_printable}
          item={item}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Update
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

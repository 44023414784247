import React, { useState, useEffect } from "react";

//mui
import {
  Button,
  TextField,
  Grid,
  Avatar,
  Paper,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  CssBaseline,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

//router
import { Link } from "react-router-dom";
import API_URL from "../utils/api";

//redux
import { useSelector, useDispatch } from "react-redux";
import { login } from "../redux/actions/userActions";

//components & others
import Notification from "../utils/notifications";
import logo from "../assets/images/logo.png";
import backgroundImg from "../assets/images/Qr-code.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  loginGrid: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  paper: {
    margin: theme.spacing(1, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="http://clonestudiobd.com/">
        Clone Studio
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Login = ({ location, history }) => {
  const classes = useStyles();

  const [employee_id, setUserId] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  console.log("api", API_URL);

  const userLogin = useSelector((state) => state.userLogin);
  console.log("userData", userLogin);
  const { error, userInfo } = userLogin;
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const redirect = location.search ? location.search.split("=")[1] : "/app";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    } else if (error) {
      setNotify({
        isOpen: true,
        message: "Invalid User or Password!",
        type: "error",
      });
    }
  }, [history, userInfo, error, redirect]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(employee_id, password));
  };

  console.log("userData", userInfo);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
      component="main"
      className={classes.root}
    >
      <CssBaseline />
      <Grid item xs={false} sm={4} md={4} />
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        component={Paper}
        elevation={2}
        square
        className={classes.loginGrid}
      >
        <div className={classes.paper}>
          <Avatar
            src={logo}
            variant="square"
            style={{
              height: "100px",
              width: "100px",
            }}
          />

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              fullWidth
              id="email"
              label="Employee Id"
              name="user name"
              autoComplete="email"
              autoFocus
              value={employee_id}
              onChange={(e) => setUserId(e.target.value)}
            />

            <TextField
              variant="outlined"
              margin="normal"
              size="small"
              required
              type="password"
              fullWidth
              id="password"
              label="Password"
              name="password"
              autoComplete="current-password"
              // autoFocus
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Grid container>
              <Grid item xs>
                <FormControlLabel
                  control={
                    <Checkbox size="small" value="remember" color="primary" />
                  }
                  label={<p style={{ fontSize: 14, margin: 0 }}>Remember me</p>}
                />
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={submitHandler}
            >
              Sign In
            </Button>
            <Box mt={5}></Box>
          </form>
        </div>
      </Grid>
      <Grid item xs={false} sm={4} md={4} />
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default Login;

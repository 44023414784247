import {
  FABRIC_REQUEST,
  FABRIC_SUCCESS,
  FABRIC_FAIL,
  FABRIC_CREATE_REQUEST,
  FABRIC_CREATE_SUCCESS,
  FABRIC_CREATE_FAIL,
  FABRIC_CREATE_RESET,
  FABRIC_UPDATE_REQUEST,
  FABRIC_UPDATE_SUCCESS,
  FABRIC_UPDATE_FAIL,
  FABRIC_UPDATE_RESET,
  FABRIC_DELETE_REQUEST,
  FABRIC_DELETE_SUCCESS,
  FABRIC_DELETE_FAIL,
} from "../constants/fabricConstant";

export const fabricReducer = (state = { fabrics: [] }, action) => {
  switch (action.type) {
    case FABRIC_REQUEST:
      return { loading: true, fabrics: [] };
    case FABRIC_SUCCESS:
      return { loading: false, fabrics: action.payload };
    case FABRIC_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const fabricCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case FABRIC_CREATE_REQUEST:
      return { loading: true };
    case FABRIC_CREATE_SUCCESS:
      return { loading: false, fabricCreate: action.payload };
    case FABRIC_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case FABRIC_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const fabricUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case FABRIC_UPDATE_REQUEST:
      return { loading: true };
    case FABRIC_UPDATE_SUCCESS:
      return { loading: false, fabricUpdate: action.payload };
    case FABRIC_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case FABRIC_UPDATE_RESET:
      return { fabricCreate: {} };
    default:
      return state;
  }
};

export const fabricDeleteReducer = (state = { fabricsDelete: [] }, action) => {
  switch (action.type) {
    case FABRIC_DELETE_REQUEST:
      return { loading: true, fabricsDelete: [] };
    case FABRIC_DELETE_SUCCESS:
      return { loading: false, success: true, fabricsDelete: action.payload };
    case FABRIC_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

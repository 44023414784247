import axios from "axios";

import {
  TRACKING_JOBS_LIST_REQUEST,
  TRACKING_JOBS_LIST_SUCCESS,
  TRACKING_JOBS_LIST_FAIL,
  TRACKING_JOBS_CREATE_REQUEST,
  TRACKING_JOBS_CREATE_SUCCESS,
  TRACKING_JOBS_CREATE_FAIL,
  TRACKING_JOBS_UPDATE_REQUEST,
  TRACKING_JOBS_UPDATE_SUCCESS,
  TRACKING_JOBS_UPDATE_FAIL,
  TRACKING_JOBS_DELETE_REQUEST,
  TRACKING_JOBS_DELETE_SUCCESS,
  TRACKING_JOBS_DELETE_FAIL,
} from "../constants/trackingJobsConstant";
import API_URL from "../../utils/api";

export const trackingJobsList = () => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_JOBS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.get(`${API_URL}/api/tracking-jobs`, config);

    dispatch({
      type: TRACKING_JOBS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_JOBS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const trackingJobsListFix = (item) => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_JOBS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    const { data } = await axios.post(
      `${API_URL}/api/tracking-jobs/fixData`,
      item,
      config
    );

    dispatch({
      type: TRACKING_JOBS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_JOBS_LIST_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const trackingJobsCreateAction =
  (order_item_id, job_date, tracking_qty, remarks) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_JOBS_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `${API_URL}/api/tracking-jobs`,
          { order_item_id, job_date, tracking_qty, remarks },
          config
        )
        .then((res) => {
          console.log(res.data);
        });

      dispatch({
        type: TRACKING_JOBS_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_JOBS_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingJobsUpdateAction =
  (id, order_item_id, job_date, tracking_qty, remarks) => async (dispatch) => {
    try {
      dispatch({
        type: TRACKING_JOBS_UPDATE_REQUEST,
      });

      console.log("id", id);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios
        .post(
          `${API_URL}/api/tracking-jobs/${id}`,
          {
            order_item_id,
            job_date,
            tracking_qty,
            remarks,
          },
          config
        )
        .then((res) => {
          console.log("abhbcd", res.data);
        });

      dispatch({
        type: TRACKING_JOBS_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: TRACKING_JOBS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const trackingJobsDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_JOBS_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(`${API_URL}/api/tracking-jobs/${id}`, config);

    dispatch({
      type: TRACKING_JOBS_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_JOBS_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

import React, { useEffect, useState } from "react";

//mui
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { measuringUnitList } from "../../../redux/actions/measuringUnitAction";
import { permissionAction } from "../../../redux/actions/permissionActions";

// import { permissionAction } from "../actions/userActions";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const dispatch = useDispatch();
  const { userData } = props;
  const [app_menu_id, setAppMenuId] = useState("Unit of Measurements");
  const measuringUnit = useSelector((state) => state.measuringUnit);

  const { measuringUnits } = measuringUnit;

  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;
  console.log("permission", permissions);

  console.log(userData);

  useEffect(() => {
    dispatch(measuringUnitList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData]);

  const classes = useStyles();

  return (
    <div style={{ width: "100%", minHeight: "65vh" }}>
      <div
        style={{
          marginTop: 20,
        }}
      >
        {permissions.length ? (
          <Table measuringUnits={measuringUnits} permissions={permissions} />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "65vh",
            }}
          >
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;

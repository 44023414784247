import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Image,
  StyleSheet,
  View,
  Text,
  PDFDownloadLink,
} from "@react-pdf/renderer";

import { Button } from "@mui/material";

const styles = StyleSheet.create({
  title: { width: "100%", marginBottom: 5, borderBottom: "1px solid black" },
  page: { padding: 40 },
  box: { width: "100%", marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
  },
});

const MyDocument = (props) => {
  const { pdfData } = props;
  const [isReady, setIsReady] = useState(false);

  console.log("pdfData", pdfData);

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true);
    }, 2000);
  }, [pdfData]);

  return (
    <Document>
      <Page style={styles.page} orientation="landscape" size="A4" wrap>
        {isReady && pdfData.length ? (
          pdfData.map((item) => {
            console.log("data", pdfData);
            return (
              <Image
                style={{
                  color: "black",
                  textAlign: "center",
                  margin: 30,
                  height: 100,
                  width: 100,
                  border: "2pt solid #999898",
                }}
                key={item.id}
                src={{
                  uri: item.tracking_code_path,
                  method: "GET",
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods":
                      "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                  },
                  body: "",
                }}
              />
            );
          })
        ) : (
          <View>
            <Text>Loading...</Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

export default MyDocument;

import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./FabricReport";
import { useLocation } from "react-router-dom";

const Index = () => {
  let location = useLocation();
  const [data, setData] = React.useState([]);
  // const [req,setReq] = useState([]);

  React.useEffect(() => {
    if (location.state) {
      setData(location.state);
    }
  }, [location]);

  console.log("data", data);

  let p = (
    <PDFDownloadLink document={<MyDocument />} fileName="somename.pdf">
      {({ blob, url, loading, error }) =>
        loading ? "Loading document..." : url
      }
    </PDFDownloadLink>
  );
  return (
    <div className="App">
      <PDFViewer width={"100%"} height={800} showToolbar={true}>
        <MyDocument data={data} />
      </PDFViewer>
    </div>
  );
};

export default Index;

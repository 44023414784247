import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    setOrderItemID,
    item,
    value,
    handleInputChange,
    orderItems,
    error,
    helperText,
    orders,
    order_id,
    setOrderID,
    tracking_job_id,
    setTrackingJobID,
    trackingCodes,
    trackingJobs,
    order_item_id,
  } = props;

  // const handleChange = (event) => {
  //   setRoleStatus(event.target.value);
  // };

  const [filteredList, setFilteredList] = React.useState("");
  const [filteredTJList, setFilteredTJList] = React.useState("");

  console.log("tttt", filteredTJList);
  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ s: 1 }}>
              <InputLabel id="demo-simple-select">Job ID *</InputLabel>
              <Select
                onChange={(e) => setTrackingJobID(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Job ID *"
                className={classes.textField}
                MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
              >
                <MenuItem value="">{item ? item.order_no : "None"}</MenuItem>
                {trackingJobs
                  ? trackingJobs.map((item) => (
                      <MenuItem value={item.job_id}>{item.job_id}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              name="remarks"
              id="remarks"
              label="Remarks"
              multiline
              fullWidth
              rows={4}
              value={value.remarks}
              error={error.remarks}
              helperText={helperText.remarks}
              variant="outlined"
              className={classes.textField}
              defaultValue={item ? item.remarks : null}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

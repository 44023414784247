import React,{ useRef } from "react";
import { useLocation } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import MyDocument from "./MyDocument";
import Printable from './Printable'
import ReactToPrint from 'react-to-print';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Print';
import "./Print.css";
const Index = () => {
  let location = useLocation();
  const [pdfData, setPdfData] = React.useState([]);
  const componentRef = useRef();
  React.useEffect(() => {
    if (location.state) {
      setPdfData(location.state);
    }
  }, [location]);

  return (
    <div>
        <div style={{margin:'1em'}}>
        <ReactToPrint
        trigger={() => <Button variant="outlined" style={{margin:'1em',float:'right'}} startIcon={<DeleteIcon />}>
        Print
        </Button>}
        content={() => componentRef.current}
      /><br/>
        <Printable pdfData={pdfData} ref={componentRef}/>
       
     
      </div>
    </div>
  );
};

export default Index;

import axios from "axios";
import {
  MEASURING_UNIT_REQUEST,
  MEASURING_UNIT_SUCCESS,
  MEASURING_UNIT_FAIL,
  MEASURING_UNIT_CREATE_REQUEST,
  MEASURING_UNIT_CREATE_SUCCESS,
  MEASURING_UNIT_CREATE_FAIL,
  MEASURING_UNIT_CREATE_RESET,
  MEASURING_UNIT_UPDATE_REQUEST,
  MEASURING_UNIT_UPDATE_SUCCESS,
  MEASURING_UNIT_UPDATE_FAIL,
  MEASURING_UNIT_UPDATE_RESET,
  MEASURING_UNIT_DELETE_REQUEST,
  MEASURING_UNIT_DELETE_SUCCESS,
  MEASURING_UNIT_DELETE_FAIL,
} from "../constants/measuringUnitConstant";
import API_URL from "../../utils/api";

export const measuringUnitList = () => async (dispatch) => {
  try {
    dispatch({
      type: MEASURING_UNIT_REQUEST,
    });

    const { data } = await axios.get(`${API_URL}/api/measuring-units`);

    dispatch({
      type: MEASURING_UNIT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEASURING_UNIT_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

export const measuringUnitCreateAction =
  (uom, description, created_by, modified_by) => async (dispatch) => {
    try {
      dispatch({
        type: MEASURING_UNIT_CREATE_REQUEST,
      });

      const config = {
        headers: {
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/measuring-units`,
        {
          uom,
          description,
          created_by,
          modified_by,
        },
        config
      );

      console.log("data4", uom, description, created_by, modified_by);

      dispatch({
        type: MEASURING_UNIT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEASURING_UNIT_CREATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const measuringUnitUpdateAction =
  (id, uom, description, created_by, modified_by) => async (dispatch) => {
    try {
      dispatch({
        type: MEASURING_UNIT_UPDATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const { data } = await axios.post(
        `${API_URL}/api/measuring-units/${id}`,
        {
          uom,
          description,
          created_by,
          modified_by,
        },
        config
      );

      console.log("update data", uom, description, created_by, modified_by);

      dispatch({
        type: MEASURING_UNIT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: MEASURING_UNIT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.errors
            ? error.response.data.errors
            : error.errors,
      });
    }
  };

export const measuringUnitDeleteAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: MEASURING_UNIT_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    console.log("id", id);

    await axios.delete(`${API_URL}/api/measuring-units/${id}`, config);

    dispatch({
      type: MEASURING_UNIT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: MEASURING_UNIT_DELETE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.error,
    });
  }
};

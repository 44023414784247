import React from "react";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import MyDocument from "./FabricReport";
import { useLocation } from "react-router-dom";

const Index = () => {
  let location = useLocation();
  const [reqData, setReqData] = React.useState([]);
  const [resData, setResData] = React.useState([]);

  React.useEffect(() => {
    if (location.state) {
      setReqData(location.state.request);
      setResData(location.state.response);
    }
  }, [location]);

  console.log("data111", reqData);

  return (
    <div className="App">
      <PDFViewer width={"100%"} height={800} showToolbar={true}>
        <MyDocument reqData={reqData} resData={resData} />
      </PDFViewer>
    </div>
  );
};

export default Index;

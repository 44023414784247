export const FABRIC_REQUEST = "FABRIC_REQUEST";
export const FABRIC_SUCCESS = "FABRIC_SUCCESS";
export const FABRIC_FAIL = "FABRIC_FAIL";

export const FABRIC_CREATE_REQUEST = "FABRIC_CREATE_REQUEST";
export const FABRIC_CREATE_SUCCESS = "FABRIC_CREATE_SUCCESS";
export const FABRIC_CREATE_FAIL = "FABRIC_CREATE_FAIL";
export const FABRIC_CREATE_RESET = "FABRIC_CREATE_RESET";

export const FABRIC_UPDATE_REQUEST = "FABRIC_UPDATE_REQUEST";
export const FABRIC_UPDATE_SUCCESS = "FABRIC_UPDATE_SUCCESS";
export const FABRIC_UPDATE_FAIL = "FABRIC_UPDATE_FAIL";
export const FABRIC_UPDATE_RESET = "FABRIC_UPDATE_RESET";

export const FABRIC_DELETE_REQUEST = "FABRIC_DELETE_REQUEST";
export const FABRIC_DELETE_SUCCESS = "FABRIC_DELETE_SUCCESS";
export const FABRIC_DELETE_FAIL = "FABRIC_DELETE_FAIL";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

//components
import PrivateRoute from "./components/utils/route/PrivateRoute";
import Layout from "./components/layout";
import Login from "./components/pages/Login";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/Login" exact component={Login}></Route>
        <PrivateRoute path="/app" component={Layout} />
        <Redirect exact from="/" to="/login" />
      </Switch>
    </Router>
  );
}

export default App;

import React from "react";

import {
  Grid,
  Typography,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  NativeSelect,
  Select,
  MenuItem,
} from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    fontSize: 12,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    "&:hover": {
      backgroundColor: "whitesmoke",
    },
  },
}));

const InputForm = (props) => {
  const classes = useStyles();

  const {
    item,
    value,
    handleInputChange,
    error,
    helperText,
    u_id,
    setUomqty,
    setUomtracking,
    measuringUnits,
    uom_qty,
    uom_tracking,
    set_job_date,
    job_date,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className={classes.root}>
      <form className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              name="job_id"
              variant="outlined"
              // required
              fullWidth
              id="job_id"
              label="Job Id"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.job_id : u_id}
              error={error.job_id}
              helperText={helperText.job_id}
              onChange={handleInputChange}
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              name="job_date"
              variant="outlined"
              required
              fullWidth
              id="job_date"
              label="Job Date"
              type="date"
              autoFocus
              size="small"
              defaultValue={item ? item.job_date : job_date}
              className={classes.textField}
              helperText={helperText.job_date}
              onChange={(e) => set_job_date(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="buyer_name"
              variant="outlined"
              required
              fullWidth
              id="buyer_name"
              label="Buyer Name"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.buyer_name : null}
              error={error.buyer_name}
              helperText={helperText.buyer_name}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="po"
              variant="outlined"
              required
              fullWidth
              id="po"
              label="PO"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.po : null}
              error={error.po}
              helperText={helperText.po}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="style"
              variant="outlined"
              required
              fullWidth
              id="style"
              label="Style"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.style : null}
              error={error.style}
              helperText={helperText.style}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              name="type"
              variant="outlined"
              fullWidth
              id="type"
              label="Carton Type"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.type : null}
              error={error.type}
              helperText={helperText.type}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="order_qty"
              variant="outlined"
              // required
              fullWidth
              id="order_qty"
              label="Order QTY"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.order_qty : null}
              error={error.order_qty}
              helperText={helperText.order_qty}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth sx={{ s: 1 }} size="small">
              <InputLabel id="demo-simple-select-label">Order UOM *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Order UOM*"
                onChange={(e) => setUomqty(e.target.value)}
                className={classes.textField}
                MenuProps={MenuProps}
                defaultValue={item ? item.uom_qty : uom_qty}
              >
                {measuringUnits
                  ? measuringUnits.map((item) => (
                      <MenuItem value={item.uom}>{item.uom}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
              name="uom_qty"
              variant="outlined"
              required
              fullWidth
              id="uom_qty"
              label="Order UOM"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.uom_qty : null}
              error={error.uom_qty}
              helperText={helperText.uom_qty}
              onChange={handleInputChange}
            />
          </Grid> */}

          <Grid item xs={6}>
            <TextField
              name="tracking_qty"
              variant="outlined"
              required
              fullWidth
              id="tracking_qty"
              label="Carton QTY"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.tracking_qty : null}
              error={error.tracking_qty}
              helperText={helperText.tracking_qty}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth sx={{ s: 1 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Carton UOM *
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Carton UOM *"
                onChange={(e) => setUomtracking(e.target.value)}
                className={classes.textField}
                MenuProps={MenuProps}
                defaultValue={item ? item.uom_tracking : uom_tracking}
              >
                {measuringUnits
                  ? measuringUnits.map((item) => (
                      <MenuItem value={item.uom}>{item.uom}</MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
              name="uom_tracking"
              variant="outlined"
              required
              fullWidth
              id="uom_tracking"
              label="Carton UOM"
              autoFocus
              size="small"
              className={classes.textField}
              defaultValue={item ? item.uom_tracking : null}
              error={error.uom_tracking}
              helperText={helperText.uom_tracking}
              onChange={handleInputChange}
            />
          </Grid> */}
        </Grid>
      </form>
    </div>
  );
};

export default InputForm;

import { makeStyles } from "@mui/styles";

// const drawerWidth = 240;

export default makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100vw",
    minHeight: "100vh",
    overflowX: "hidden",
    backgroundColor: "white",
    margin: 0,
  },
  contentContainer: {
    flexGrow: 1,
    width: `calc(100vw - 260px)`,
    minHeight: "100vh",
    top: theme.spacing(8),
  },
  content: {
    flexGrow: 1,
    width: `calc(100vw - 260px)`,
    minHeight: "100vh",
  },
  fab: {
    flexGrow: 1,
    position: "relative",
    top: theme.spacing(8),
    width: "100%",
    height: theme.spacing(1),
  },
  fabFixed: {
    position: "fixed",
    width: 18,
    height: 18,
    backgroundColor: "#13292A",
    borderBottomRightRadius: "50%",
    borderTopRightRadius: "50%",
    marginTop: theme.spacing(1),
  },
  fabShift: {
    width: "100%",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },
}));

import React, { useState, useEffect } from "react";

export function CreateValidation(validateOnChange = false, validate) {
  const [values, setValues] = useState({
    order_item_id: "",
    job_date: "",
    buyer_name: "",
    style: "",
    po: "",
    pi: "",
    shipment_date: "",
    type: "",
    category: "",
    composition: "",
    construction: "",
    width: "",
    color: "",
    gsm: "",
    lot_qty_roll: "",
    lot_qty_yds: "",
    order_qty_roll: "",
    order_qty_yds: "",
    uom_qty: "",
    lot_no: "",
    tracking_qty: "",
    item_type: "",
    conversion_rate: "",
    lot_conversion_rate: "",
    shrinkage_length: "",
    shrinkage_width: "",
    pattern: "",
    shade: "",
    others: "",
    remarks: "",
    job_id: "",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("name", name);
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  console.log("bbb", values);

  const resetForm = () => {
    setValues({
      order_item_id: "",
      job_date: "",
      buyer_name: "",
      style: "",
      po: "",
      pi: "",
      shipment_date: "",
      type: "",
      category: "",
      composition: "",
      construction: "",
      width: "",
      color: "",
      gsm: "",
      lot_qty_roll: "",
      lot_qty_yds: "",
      order_qty_roll: "",
      order_qty_yds: "",
      uom_qty: "",
      lot_no: "",
      tracking_qty: "",
      item_type: "",
      conversion_rate: "",
      lot_conversion_rate: "",
      shrinkage_length: "",
      shrinkage_width: "",
      pattern: "",
      shade: "",
      others: "",
      remarks: "",
      job_id: "",
    });
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

export function EditValidation(item, validateOnChange = false, validate) {
  const [values, setValues] = useState({
    order_item_id: "",
    job_date: "",
    buyer_name: "",
    style: "",
    po: "",
    pi: "",
    shipment_date: "",
    type: "",
    category: "",
    composition: "",
    construction: "",
    width: "",
    color: "",
    gsm: "",
    lot_qty_roll: "",
    lot_qty_yds: "",
    order_qty_roll: "",
    order_qty_yds: "",
    uom_qty: "",
    lot_no: "",
    tracking_qty: "",
    item_type: "",
    conversion_rate: "",
    lot_conversion_rate: "",
    shrinkage_length: "",
    shrinkage_width: "",
    pattern: "",
    shade: "",
    others: "",
    remarks: "",
    job_id: "",
  });
  const [errors, setErrors] = useState({});

  console.log("val", values);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    if (validateOnChange) validate({ [name]: value });
  };

  useEffect(() => {
    setValues({
      order_item_id: item.order_item_id,
      job_date: item.job_date,
      tracking_qty: item.tracking_qty,
      buyer_name: item.buyer_name,
      style: item.style,
      po: item.po,
      type: item.type,
      category: item.category,
      composition: item.composition,
      construction: item.construction,
      width: item.width,
      color: item.color,
      gsm: item.gsm,
      uom_tracking: item.uom_tracking,
      order_qty: item.order_qty,
      uom_qty: item.uom_qty,
      item_type: item.item_type,
      conversion_rate: item.conversion_rate,
      others: item.others,
      remarks: item.remarks,
      job_id: item.job_id,
      pi: item.pi_no,
      shipment_date: item.shipment_date,
      lot_no: item.lot_no,
      lot_conversion_rate: item.lot_conversion_rate,
      shrinkage_length: item.shrinkage_length,
      shrinkage_width: item.shrinkage_width,
      pattern: item.pattern,
      shade: item.shade,
      lot_qty_roll: item.tracking_qty,
      lot_qty_yds: item.tracking_qty * item.lot_conversion_rate,
      order_qty_roll: item.order_qty,
      order_qty_yds: item.order_qty * item.conversion_rate,
    });
  }, [item]);

  console.log("bbb", values);

  const resetForm = () => {
    setValues(values);
    setErrors({});
  };

  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,
  };
}

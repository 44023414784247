import React from "react";
import { withStyles } from "@mui/styles";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
// import { userCreateAction, userList } from "../actions/userActions";
import { CreateValidation } from "./FormValidation";

//redux
import {
  trackingCodesCreateAction,
  trackingCodesListFix,
} from "../../../redux/actions/trackingCodesAction";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>pin</Icon>
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    notify,
    setNotify,
    orders,
    orderItems,
    trackingCodes,
    trackingJobs,
  } = props;
  const [role_status, setRoleStatus] = React.useState("A");
  const [order_item_id, setOrderItemID] = React.useState("");
  const [order_id, setOrderID] = React.useState("");
  const [tracking_job_id, setTrackingJobID] = React.useState("");

  const dispatch = useDispatch();
  const roleCreate = useSelector((state) => state.roleCreate);
  const { roleCreates } = roleCreate;

  const trackingCodeCreate = useSelector((state) => state.trackingCodeCreate);
  const { trackingCodesCreate } = trackingCodeCreate;

  console.log("added", trackingCodesCreate);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("role_name" in fieldValues)
      temp.role_name = fieldValues.role_name ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const job_date = values.job_date;
  const tracking_qty = values.tracking_qty;
  const remarks = values.remarks;

  console.log("post-Data", order_item_id, job_date, tracking_qty, remarks);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validate()) {
      dispatch(trackingCodesCreateAction(tracking_job_id, remarks));
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(trackingCodesListFix({ item_type: "Carton" }));
      }, 2000);

      resetForm();
    }
  };

  React.useEffect(() => {
    if (trackingCodesCreate.message) {
      setNotify({
        isOpen: true,
        message: "QR Code Already Generated for This Job.",
        type: "error",
      });
    }
  }, [trackingCodesCreate]);

  React.useEffect(() => {
    setNotify({
      isOpen: false,
      message: "",
      type: "error",
    });
  }, []);

  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={openPopup}
      fullWidth
    >
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          setOrderItemID={setOrderItemID}
          orders={orders}
          orderItems={orderItems}
          setOrderID={setOrderID}
          order_id={order_id}
          tracking_job_id={tracking_job_id}
          setTrackingJobID={setTrackingJobID}
          trackingCodes={trackingCodes}
          trackingJobs={trackingJobs}
          order_item_id={order_item_id}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

export const CARTOON_REQUEST = "CARTOON_REQUEST";
export const CARTOON_SUCCESS = "CARTOON_SUCCESS";
export const CARTOON_FAIL = "CARTOON_FAIL";

export const CARTOON_CREATE_REQUEST = "CARTOON_CREATE_REQUEST";
export const CARTOON_CREATE_SUCCESS = "CARTOON_CREATE_SUCCESS";
export const CARTOON_CREATE_FAIL = "CARTOON_CREATE_FAIL";
export const CARTOON_CREATE_RESET = "CARTOON_CREATE_RESET";

export const CARTOON_UPDATE_REQUEST = "CARTOON_UPDATE_REQUEST";
export const CARTOON_UPDATE_SUCCESS = "CARTOON_UPDATE_SUCCESS";
export const CARTOON_UPDATE_FAIL = "CARTOON_UPDATE_FAIL";
export const CARTOON_UPDATE_RESET = "CARTOON_UPDATE_RESET";

export const CARTOON_DELETE_REQUEST = "CARTOON_DELETE_REQUEST";
export const CARTOON_DELETE_SUCCESS = "CARTOON_DELETE_SUCCESS";
export const CARTOON_DELETE_FAIL = "CARTOON_DELETE_FAIL";

import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import { Box, TextField, InputAdornment, Grid, Icon } from "@mui/material";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles, withStyles, useTheme } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import TableFooter from "@mui/material/TableFooter";
import API_URL from "../../../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { requisitionList } from "../../../../redux/actions/requisitionAction";
import { useHistory } from "react-router-dom";

import Popup from "./ReqCreatePopup";
// import EditPopup from "./EditPopup";
import DeletePopup from "./DeletePopup";
// import Notification from "../../../utils/notifications";

function createData(name, calories, fat, carbs, protein) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Donut", 452, 25.0, 51, 4.9),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
  createData("Honeycomb", 408, 3.2, 87, 6.5),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0),
  createData("KitKat", 518, 26.0, 65, 7.0),
  createData("Lollipop", 392, 0.2, 98, 0.0),
  createData("Marshmallow", 318, 0, 81, 2.0),
  createData("Nougat", 360, 19.0, 9, 37.0),
  createData("Oreo", 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator, searchValues) {
  const stabilizedThis = searchValues.fn(array).map((el, id) => [el, id]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "Req. No",
  },
  {
    id: "job_date",
    numeric: false,
    disablePadding: true,
    label: "Roll Qty",
  },

  {
    id: "buyer_name",
    numeric: false,
    disablePadding: true,
    label: "Yds Qty",
  },

  { id: 8, numeric: false, disablePadding: false, label: "Actions" },
  {
    id: "style",
    numeric: false,
    disablePadding: false,
    label: "Print",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ top: 57, minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {/* {order === "desc" ? "sorted descending" : "sorted ascending"} */}
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleSearch } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <div style={{ flexGrow: 1 }}>
          <TextField
            label="Search"
            id="outlined-size-small"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
          />
        </div>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

// EnhancedTableToolbar.propTypes = {
//   numSelected: PropTypes.number.isRequired,
// };

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  root: {},
  table: {},
  tableCell: {
    fontSize: "0.1em",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  title: {
    flex: "1 1 100%",
  },
  pagination: {
    "& .MuiTablePagination-spacer": {},
  },
});

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function EnhancedTable(props) {
  const { reportData, reqData, qrList, stockCount } = props;
  let history = useHistory();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);

  const [rowsPerPage, setRowsPerPage] = React.useState(9);
  const [searchValues, setSearchValues] = React.useState({
    fn: (requisitions) => {
      return requisitions;
    },
  });

  const [item, setItem] = React.useState("");
  const [id, setId] = React.useState("");
  const [notify, setNotify] = React.useState({
    isOpen: false,
    message: "",
    type: "",
  });

  //
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openEditPopup, setOpenEditPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);

  //
  const classes = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = requisitions.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSearch = (e) => {
    let target = e.target;
    setSearchValues({
      fn: (requisitions) => {
        if (target.value === "") return requisitions;
        else
          return requisitions.filter(
            (x) =>
              (x.buyer_name
                ? x.buyer_name.toString().toLowerCase().includes(target.value)
                : null) ||
              (x.color
                ? x.color.toString().toLowerCase().includes(target.value)
                : null) ||
              (x.style
                ? x.style.toString().toLowerCase().includes(target.value)
                : null) ||
              (x.tracking_qty
                ? x.tracking_qty.toString().toLowerCase().includes(target.value)
                : null)
          );
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  //api-data

  const requisition = useSelector((state) => state.requisition);
  const { requisitions } = requisition;

  React.useEffect(() => {
    dispatch(requisitionList());
  }, [dispatch]);

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requisitions.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", marginBottom: 20 }}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={8} sm={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3 style={{ marginLeft: 5, color: "#13292A" }}>
                Fab. Requisition List
              </h3>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={12}>
            <div style={{ marginTop: 25 }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                style={{ fontSize: 12, color: "#13292A" }}
                style={{ minWidth: 150, minHeight: 40, marginBottom: 10 }}
                onClick={() => setOpenPopup(true)}
              >
                Create Requisition
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      <Paper sx={{ width: "100%" }}>
        <TableContainer>
          <Table sx={{}} aria-labelledby="tableTitle" size={"small"}>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={requisitions ? requisitions.length : null}
            />
            <TableBody>
              {stableSort(
                requisitions,
                getComparator(order, orderBy),
                searchValues
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, id) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${id}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      style={{ minWidth: "100%" }}
                    >
                      <TableCell
                        id={labelId}
                        // padding="checkbox"
                        align="left"
                        style={{ fontSize: 12, width: "10%" }}
                      >
                        {row.id}
                      </TableCell>
                      <TableCell
                        id={labelId}
                        // padding="checkbox"
                        align="left"
                        style={{ fontSize: 12, width: "10%" }}
                      >
                        {row.req_roll_qty}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "15%" }}
                        // padding="checkbox"
                      >
                        {row.req_yds_qty}
                      </TableCell>

                      <TableCell
                        align="left"
                        padding="checkbox"
                        style={{ width: "5%" }}
                      >
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              setId(row.id);
                              setOpenDeletePopup(true);
                              setItem(row);
                            }}
                          >
                            <DeleteIcon
                              fontSize="small"
                              style={{
                                fill: "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>

                        <BootstrapTooltip
                          title={
                            <div>
                              <p>Created By : {row.created_by}</p>
                              <p>Created Date : {row.created_date}</p>
                              <p>Modified By : {row.modified_by}</p>
                              <p>Modified Date : {row.modified_date}</p>
                            </div>
                          }
                        >
                          <IconButton aria-label="info" size="small">
                            <InfoIcon
                              fontSize="small"
                              style={{ fill: "	#ffcc00" }}
                            />
                          </IconButton>
                        </BootstrapTooltip>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ fontSize: 12, width: "5%" }}
                      >
                        <Tooltip title="Print" placement="right-start">
                          <IconButton
                            aria-label="print"
                            size="small"
                            onClick={() => {
                              history.push({
                                pathname: `/app/doc/fabric_requisition`,
                                state: row,
                              });
                            }}
                          >
                            <PrintIcon
                              fontSize="small"
                              style={{
                                fill: "black",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[9, 15, 25, { label: "All", value: -1 }]}
          colSpan={3}
          count={requisitions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          style={{
            justifyContent: "flex-end",
            display: "flex",
          }}
        />
      </Paper>
      <Popup
        setOpenPopup={setOpenPopup}
        openPopup={openPopup}
        requisitions={requisitions}
        reportData={reportData}
        reqData={reqData}
        qrList={qrList}
        stockCount={stockCount}
      />
      <DeletePopup
        setOpenDeletePopup={setOpenDeletePopup}
        openDeletePopup={openDeletePopup}
        id={id}
        item={item}
      />
    </Box>
  );
}

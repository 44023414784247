import {
  TRACKING_JOBS_LIST_REQUEST,
  TRACKING_JOBS_LIST_SUCCESS,
  TRACKING_JOBS_LIST_FAIL,
  TRACKING_JOBS_CREATE_REQUEST,
  TRACKING_JOBS_CREATE_SUCCESS,
  TRACKING_JOBS_CREATE_FAIL,
  TRACKING_JOBS_UPDATE_REQUEST,
  TRACKING_JOBS_UPDATE_SUCCESS,
  TRACKING_JOBS_UPDATE_FAIL,
  TRACKING_JOBS_DELETE_REQUEST,
  TRACKING_JOBS_DELETE_SUCCESS,
  TRACKING_JOBS_DELETE_FAIL,
} from "../constants/trackingJobsConstant";

export const trackingJobsListReducer = (
  state = { trackingJobs: [] },
  action
) => {
  switch (action.type) {
    case TRACKING_JOBS_LIST_REQUEST:
      return { loading: true, trackingJobs: [] };
    case TRACKING_JOBS_LIST_SUCCESS:
      return { loading: false, trackingJobs: action.payload };
    case TRACKING_JOBS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingJobsCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_JOBS_CREATE_REQUEST:
      return { loading: true };
    case TRACKING_JOBS_CREATE_SUCCESS:
      return { loading: false, trackingJobsCreates: action.payload };
    case TRACKING_JOBS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingJobsUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_JOBS_UPDATE_REQUEST:
      return { loading: true };
    case TRACKING_JOBS_UPDATE_SUCCESS:
      return { loading: false, trackingJobsUpdates: action.payload };
    case TRACKING_JOBS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingJobsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_JOBS_DELETE_REQUEST:
      return { loading: true };
    case TRACKING_JOBS_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRACKING_JOBS_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

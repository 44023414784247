import React from "react";
import { withStyles } from "@mui/styles";
import axios from "axios";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Icon,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import InputForm from "./InputForm";
import { useDispatch, useSelector } from "react-redux";
// import { userCreateAction, userList } from "../actions/userActions";
import { CreateValidation } from "./FormValidation";
import {
  cartoonList,
  cartoonCreateAction,
} from "../../../redux/actions/cartoonAction";
// import { userRoleList } from "../../../redux/actions/userRoleActions";
const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    height: "60px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const Dialog_Title = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Icon style={{ fontSize: 25, color: "whitesmoke" }}>assessment</Icon>
      <Typography
        variant="h5"
        style={{ marginLeft: 10, fontSize: 16, fontWeight: "500", flexGrow: 1 }}
      >
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon style={{ color: "red" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const Dialog_Content = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(DialogContent);

const Dialog_Actions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(DialogActions);

const Popup = (props) => {
  const {
    openPopup,
    setOpenPopup,
    notify,
    setNotify,
    measuringUnits,
    cartoons,
  } = props;

  const [uom_qty, setUomqty] = React.useState("Pcs");
  const [uom_tracking, setUomtracking] = React.useState("Pcs");
  const [u_id, setU_id] = React.useState(1);

  const created_by = "Shahibuzzaman";
  const modified_by = "Shahibuzzaman2";

  const dispatch = useDispatch();
  const cartoonCreate = useSelector((state) => state.cartoonCreate);
  const { cartoonCreates } = cartoonCreate;
  console.log("cartonsData", cartoonCreates);

  const dateNow = new Date(); // Creating a new date object with the current date and time
  const year = dateNow.getFullYear(); // Getting current year from the created Date object
  const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
  const month = // Setting current Month number from current Date object
    monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const [job_date, set_job_date] = React.useState(materialDateInput);

  React.useEffect(() => {
    axios
      .get("http://tracking.clonestudiobd.com/api/tracking_jobs/max-job-id")
      .then((res) => {
        console.log("response ----:", res);
        setU_id(res.data);
      })
      .catch((err) => {
        console.log("Error ---:", err);
      });
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    // if ("job_date" in fieldValues)
    //   temp.job_date = fieldValues.job_date ? "" : "This field is required.";

    if ("buyer_name" in fieldValues)
      temp.buyer_name = fieldValues.buyer_name ? "" : "This field is required.";

    if ("po" in fieldValues)
      temp.po = fieldValues.po ? "" : "This field is required.";

    if ("style" in fieldValues)
      temp.style = fieldValues.style ? "" : "This field is required.";

    if ("tracking_qty" in fieldValues)
      temp.tracking_qty = fieldValues.tracking_qty
        ? ""
        : "This field is required.";

    // if ("uom_qty" in fieldValues)
    //   temp.uom_qty = fieldValues.uom_qty ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues == values) return Object.values(temp).every((x) => x == "");
  };

  // const u_id = "C#" + uuidv4().substring(0, 15);

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    CreateValidation(true, validate);

  const order_item_id = values.order_item_id;
  const job_id = u_id;
  const tracking_qty = values.tracking_qty;
  const buyer_name = values.buyer_name;
  const style = values.style;
  const po = values.po;
  const type = values.type;
  const category = values.category;
  const composition = values.composition;
  const construction = values.construction;
  const width = values.width;
  const color = values.color;
  const gsm = values.gsm;
  const order_qty = values.order_qty;
  const item_type = "Carton";
  const conversion_rate = values.conversion_rate;
  const others = values.others;
  const remarks = values.remarks;

  console.log(
    "post dataCarton",
    order_item_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    job_id
  );

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("validate Data", validate());
    if (validate()) {
      dispatch(
        cartoonCreateAction(
          order_item_id,
          job_id,
          job_date,
          tracking_qty,
          buyer_name,
          style,
          po,
          type,
          category,
          composition,
          construction,
          width,
          color,
          gsm,
          uom_tracking,
          order_qty,
          uom_qty,
          item_type,
          conversion_rate,
          others,
          remarks
        )
      );
      setOpenPopup(false);
      setTimeout(() => {
        dispatch(cartoonList());
      }, 2000);

      resetForm();
    }
  };

  console.log("post CartonData____", {
    order_item_id,
    job_date,
    tracking_qty,
    buyer_name,
    style,
    po,
    type,
    category,
    composition,
    construction,
    width,
    color,
    gsm,
    uom_tracking,
    order_qty,
    uom_qty,
    item_type,
    conversion_rate,
    others,
    remarks,
    job_id,
  });
  const handleClose = () => {
    setOpenPopup(false);
    resetForm();
  };

  return (
    <Dialog aria-labelledby="customized-dialog-title" open={openPopup}>
      <Dialog_Title
        style={{ color: "whitesmoke" }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Create Form
      </Dialog_Title>
      <Dialog_Content dividers>
        <InputForm
          value={values}
          handleInputChange={handleInputChange}
          error={errors}
          helperText={errors}
          u_id={u_id}
          cartoons={cartoons}
          measuringUnits={measuringUnits}
          setUomqty={setUomqty}
          setUomtracking={setUomtracking}
          uom_qty={uom_qty}
          uom_tracking={uom_tracking}
          set_job_date={set_job_date}
          job_date={job_date}
        />
      </Dialog_Content>
      <Dialog_Actions style={{ padding: 20 }}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          autoFocus
          onClick={handleClose}
          style={{ marginRight: 10 }}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={submitHandler}
        >
          Submit
        </Button>
      </Dialog_Actions>
    </Dialog>
  );
};

export default Popup;

export const TRACKING_PURPOSES_REQUEST = "TRACKING_PURPOSES_REQUEST";
export const TRACKING_PURPOSES_SUCCESS = "TRACKING_PURPOSES_SUCCESS";
export const TRACKING_PURPOSES_FAIL = "TRACKING_PURPOSES_FAIL";

export const TRACKING_PURPOSES_CREATE_REQUEST = "TRACKING_PURPOSES_CREATE_REQUEST";
export const TRACKING_PURPOSES_CREATE_SUCCESS = "TRACKING_PURPOSES_CREATE_SUCCESS";
export const TRACKING_PURPOSES_CREATE_FAIL = "TRACKING_PURPOSES_CREATE_FAIL";
export const TRACKING_PURPOSES_CREATE_RESET = "TRACKING_PURPOSES_CREATE_RESET";

export const TRACKING_PURPOSES_UPDATE_REQUEST = "TRACKING_PURPOSES_UPDATE_REQUEST";
export const TRACKING_PURPOSES_UPDATE_SUCCESS = "TRACKING_PURPOSES_UPDATE_SUCCESS";
export const TRACKING_PURPOSES_UPDATE_FAIL = "TRACKING_PURPOSES_UPDATE_FAIL";
export const TRACKING_PURPOSES_UPDATE_RESET = "TRACKING_PURPOSES_UPDATE_RESET";

export const TRACKING_PURPOSES_DELETE_REQUEST = "TRACKING_PURPOSES_DELETE_REQUEST";
export const TRACKING_PURPOSES_DELETE_SUCCESS = "TRACKING_PURPOSES_DELETE_SUCCESS";
export const TRACKING_PURPOSES_DELETE_FAIL = "TRACKING_PURPOSES_DELETE_FAIL";

import {
  TRACKING_CODES_LIST_REQUEST,
  TRACKING_CODES_LIST_SUCCESS,
  TRACKING_CODES_LIST_FAIL,
  TRACKING_CODES_CREATE_REQUEST,
  TRACKING_CODES_CREATE_SUCCESS,
  TRACKING_CODES_CREATE_FAIL,
  TRACKING_CODES_UPDATE_REQUEST,
  TRACKING_CODES_UPDATE_SUCCESS,
  TRACKING_CODES_UPDATE_FAIL,
  TRACKING_CODES_DELETE_REQUEST,
  TRACKING_CODES_DELETE_SUCCESS,
  TRACKING_CODES_DELETE_FAIL,
} from "../constants/trackingCodesConstant";

export const trackingCodesListReducer = (
  state = { trackingCodes: [] },
  action
) => {
  switch (action.type) {
    case TRACKING_CODES_LIST_REQUEST:
      return { loading: true, trackingCodes: [] };
    case TRACKING_CODES_LIST_SUCCESS:
      return { loading: false, trackingCodes: action.payload };
    case TRACKING_CODES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingCodesCreateReducer = (
  state = { trackingCodesCreate: [] },
  action
) => {
  switch (action.type) {
    case TRACKING_CODES_CREATE_REQUEST:
      return { loading: true, trackingCodesCreate: [] };
    case TRACKING_CODES_CREATE_SUCCESS:
      return { loading: false, trackingCodesCreate: action.payload };
    case TRACKING_CODES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingCodesUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_CODES_UPDATE_REQUEST:
      return { loading: true };
    case TRACKING_CODES_UPDATE_SUCCESS:
      return { loading: false, trackingCodesUpdates: action.payload };
    case TRACKING_CODES_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const trackingCodesDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TRACKING_CODES_DELETE_REQUEST:
      return { loading: true };
    case TRACKING_CODES_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TRACKING_CODES_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { userList } from "../../../redux/actions/userActions";
import { permissionAction } from "../../../redux/actions/permissionActions";

const Body = (props) => {
  const dispatch = useDispatch();
  const { userData } = props;
  const [app_menu_id, setAppMenuId] = useState("Users");
  const user = useSelector((state) => state.user);
  const { users } = user;
  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;

  useEffect(() => {
    dispatch(userList());
    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData, app_menu_id]);

  return (
    <div style={{ width: "100%", minHeight: "65vh" }}>
      <div
        style={{
          marginTop: 20,
        }}
      >
        {permissions.length ? (
          <Table
            users={users}
            permissions={permissions}
            userData={userData}
            app_menu_id={app_menu_id}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "65vh",
            }}
          >
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;

export const MEASURING_UNIT_REQUEST = "MEASURING_UNIT_REQUEST";
export const MEASURING_UNIT_SUCCESS = "MEASURING_UNIT_SUCCESS";
export const MEASURING_UNIT_FAIL = "MEASURING_UNIT_FAIL";

export const MEASURING_UNIT_CREATE_REQUEST = "MEASURING_UNIT_CREATE_REQUEST";
export const MEASURING_UNIT_CREATE_SUCCESS = "MEASURING_UNIT_CREATE_SUCCESS";
export const MEASURING_UNIT_CREATE_FAIL = "MEASURING_UNIT_CREATE_FAIL";
export const MEASURING_UNIT_CREATE_RESET = "MEASURING_UNIT_CREATE_RESET";

export const MEASURING_UNIT_UPDATE_REQUEST = "MEASURING_UNIT_UPDATE_REQUEST";
export const MEASURING_UNIT_UPDATE_SUCCESS = "MEASURING_UNIT_UPDATE_SUCCESS";
export const MEASURING_UNIT_UPDATE_FAIL = "MEASURING_UNIT_UPDATE_FAIL";
export const MEASURING_UNIT_UPDATE_RESET = "MEASURING_UNIT_UPDATE_RESET";

export const MEASURING_UNIT_DELETE_REQUEST = "MEASURING_UNIT_DELETE_REQUEST";
export const MEASURING_UNIT_DELETE_SUCCESS = "MEASURING_UNIT_DELETE_SUCCESS";
export const MEASURING_UNIT_DELETE_FAIL = "MEASURING_UNIT_DELETE_FAIL";

import React, { useEffect, useState } from "react";
//mui
import CircularProgress from "@mui/material/CircularProgress";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { trackingJobsListFix } from "../../../redux/actions/trackingJobsAction";
import { permissionAction } from "../../../redux/actions/permissionActions";
import { trackingCodesListFix } from "../../../redux/actions/trackingCodesAction";

const Body = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [app_menu_id, setAppMenuId] = useState("Fabrics QR Code Generator");

  const trackingJob = useSelector((state) => state.trackingJob);

  const { trackingJobs } = trackingJob;

  const trackingCode = useSelector((state) => state.trackingCode);

  const { trackingCodes } = trackingCode;

  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;
  console.log(userData);

  const pathName = window.location.pathname;
  useEffect(() => {
    dispatch(trackingJobsListFix({ item_type: "Fabrics" }));

    dispatch(trackingCodesListFix({ item_type: "Fabrics" }));

    dispatch(permissionAction(userData, app_menu_id));
  }, [dispatch, userData, pathName, app_menu_id]);

  return (
    <div style={{ width: "100%", minHeight: "65vh" }}>
      <div
        style={{
          marginTop: 20,
        }}
      >
        {permissions.length ? (
          <Table
            trackingJobs={trackingJobs}
            permissions={permissions}
            tracking_Codes={trackingCodes}
          />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "65vh",
            }}
          >
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Body;

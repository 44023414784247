import {
    TRACKING_PURPOSES_REQUEST,
    TRACKING_PURPOSES_SUCCESS,
    TRACKING_PURPOSES_FAIL,
    TRACKING_PURPOSES_CREATE_REQUEST,
    TRACKING_PURPOSES_CREATE_SUCCESS,
    TRACKING_PURPOSES_CREATE_FAIL,
    TRACKING_PURPOSES_CREATE_RESET,
    TRACKING_PURPOSES_UPDATE_REQUEST,
    TRACKING_PURPOSES_UPDATE_SUCCESS,
    TRACKING_PURPOSES_UPDATE_FAIL,
    TRACKING_PURPOSES_UPDATE_RESET,
    TRACKING_PURPOSES_DELETE_REQUEST,
    TRACKING_PURPOSES_DELETE_SUCCESS,
    TRACKING_PURPOSES_DELETE_FAIL,
  
  } from "../constants/trackingPurposesConstants";
  
  export const trackingPurposesReducer = (state = { trackingPurposes: [] }, action) => {
    switch (action.type) {
      case TRACKING_PURPOSES_REQUEST:
        return { loading: true, trackingPurposes: [] };
      case TRACKING_PURPOSES_SUCCESS:
        return { loading: false, trackingPurposes: action.payload };
      case TRACKING_PURPOSES_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
  export const trackingPurposesCreateReducer = (state = {}, action) => {
    switch (action.type) {
      case TRACKING_PURPOSES_CREATE_REQUEST:
        return { loading: true };
      case TRACKING_PURPOSES_CREATE_SUCCESS:
        return { loading: false, trackingPurposesCreates: action.payload };
      case TRACKING_PURPOSES_CREATE_FAIL:
        return { loading: false, error: action.payload };
      case TRACKING_PURPOSES_CREATE_RESET:
        return {};
      default:
        return state;
    }
  };
  
  export const trackingPurposesUpdateReducer = (state = {}, action) => {
    switch (action.type) {
      case TRACKING_PURPOSES_UPDATE_REQUEST:
        return { loading: true };
      case TRACKING_PURPOSES_UPDATE_SUCCESS:
        return { loading: false, trackingPurposesUpdates: action.payload };
      case TRACKING_PURPOSES_UPDATE_FAIL:
        return { loading: false, error: action.payload };
      case TRACKING_PURPOSES_UPDATE_RESET:
        return { roleCreates: {} };
      default:
        return state;
    }
  };
  
  export const trackingPurposesDeleteReducer = (state = {}, action) => {
    switch (action.type) {
      case TRACKING_PURPOSES_DELETE_REQUEST:
        return { loading: true };
      case TRACKING_PURPOSES_DELETE_SUCCESS:
        return { loading: false, success: true };
      case TRACKING_PURPOSES_DELETE_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };
  
 
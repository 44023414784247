import React, { useEffect, useState } from "react";

//mui
import { makeStyles } from "@mui/styles";
import { Icon } from "@mui/material";

//components
import Table from "./Table";

//redux
import { useDispatch, useSelector } from "react-redux";
import { cartoonList } from "../../../redux/actions/cartoonAction";
import { measuringUnitList } from "../../../redux/actions/measuringUnitAction";

import { permissionAction } from "../../../redux/actions/permissionActions";

// import { permissionAction } from "../actions/userActions";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const Body = (props) => {
  const dispatch = useDispatch();
  const { appUser } = props;
  const [app_menu_id, setAppMenuId] = useState('Cartons');
  const cartoon = useSelector((state) => state.cartoon);

  const { cartoons } = cartoon;
  console.log("data__c", cartoons);

  const measuringUnit = useSelector((state) => state.measuringUnit);
  const { measuringUnits } = measuringUnit;

  const permission = useSelector((state) => state.permission);
  const { permissions } = permission;
  console.log("permission", permissions);
  
  console.log(appUser);

  useEffect(() => {
    dispatch(cartoonList());
    dispatch(measuringUnitList());

    dispatch(permissionAction(appUser, app_menu_id));
  }, [dispatch, appUser]);

  const classes = useStyles();

  const modifiedData = cartoons
    ? cartoons
        .filter((item) => {
          return item.item_type === "Carton";
        })
        .map((item) => item)
    : null;

  console.log("log", modifiedData);

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Table cartoons={modifiedData} measuringUnits={measuringUnits} permissions={permissions} />
      </div>
    </div>
  );
};

export default Body;

import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Paper,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { fabricList } from "../redux/actions/fabricAction";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DashBgImg from "../assets/images/box.jpg";
import AppPreview from "../assets/images/app-preview.png";
import { flexbox } from "@mui/system";

const SearchBox = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "30px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
}));

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.order_no,
});

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "72vh",
    maxWidth: "100%",
    backgroundImage: `url(${DashBgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginTop: 20,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [getOrderId, setOrderId] = useState("");
  let history = useHistory();

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `app-debug.apk`;
    link.href =
      "https://drive.google.com/uc?export=download&id=1NCJd7jK4Q4ONkl0uEUhciYy5-VWJpKkX";
    link.click();
  };

  const fabric = useSelector((state) => state.fabric);
  const { fabrics } = fabric;

  console.log("fabric", fabrics);

  const options = ["a", "b", "c"];

  useEffect(() => {
    dispatch(fabricList());
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (getOrderId !== "") {
      history.push({
        pathname: `/app/order-details`,
        state: getOrderId,
      });
    }
  };

  console.log("getOrderId", getOrderId);

  return (
    <Box sx={{}}>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={8} style={{ minHeight: "72vh" }}></Grid>
          <Grid
            item
            xs={4}
            style={{
              minHeight: "72vh",
              opacity: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              style={{ height: "400px", width: "200px" }}
              alt="The house from the offer."
              src={AppPreview}
            />
            <div style={{ marginTop: 20, textAlign: "center" }}>
              <Button onClick={onDownload} variant="contained" color="primary">
                Download APK
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default Dashboard;
